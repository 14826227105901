// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Connect Styles */

.connect {
    padding: 50px;
    text-align: center;
    color: var(--text-color);
}

.social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.icon {
    font-size: 2rem;
    color: var(--accent-color);
    transition: color 0.3s;
}

    .icon:hover {
        color: var(--primary-color);
        transform: scale(1.1);
    }
`, "",{"version":3,"sources":["webpack://./src/components/Connect/Connect.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB;IACI,aAAa;IACb,kBAAkB;IAClB,wBAAwB;AAC5B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;AACb;;AAEA;IACI,eAAe;IACf,0BAA0B;IAC1B,sBAAsB;AAC1B;;IAEI;QACI,2BAA2B;QAC3B,qBAAqB;IACzB","sourcesContent":["/* Connect Styles */\r\n\r\n.connect {\r\n    padding: 50px;\r\n    text-align: center;\r\n    color: var(--text-color);\r\n}\r\n\r\n.social-icons {\r\n    display: flex;\r\n    justify-content: center;\r\n    gap: 20px;\r\n}\r\n\r\n.icon {\r\n    font-size: 2rem;\r\n    color: var(--accent-color);\r\n    transition: color 0.3s;\r\n}\r\n\r\n    .icon:hover {\r\n        color: var(--primary-color);\r\n        transform: scale(1.1);\r\n    }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
