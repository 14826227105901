import React, { useState, useEffect } from 'react';
import DashJobs from '../../components/DashJobs/DashJobs';
import type { Job } from '../../components/DashJobs/DashJobs';
import DashSwarmer from '../../components/DashSwarmer/DashSwarmer';
import type { Swarmer } from '../../components/DashSwarmer/DashSwarmer';
import { useWallet } from '@solana/wallet-adapter-react';
import './Dashboard.css';

const Dashboard: React.FC = () => {
    const { publicKey, connected } = useWallet();
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:42069';

    // Load God Mode wallets from .env
    const GOD_MODE_WALLETS = (process.env.REACT_APP_GOD_MODE_WALLETS || '')
        .split(',')
        .map((wallet) => wallet.trim());

    const isGodModeWallet = publicKey && GOD_MODE_WALLETS.includes(publicKey.toBase58());

    const [activeTab, setActiveTab] = useState('jobs');
    const [metrics, setMetrics] = useState([
        { label: 'Jobs Published', value: 0, icon: 'fa-solid fa-file-alt' },
        { label: 'Jobs Completed', value: 0, icon: 'fa-solid fa-check-circle' },
        { label: 'Swarm Paid', value: '0%', icon: 'fa-solid fa-share-alt' },
        { label: 'Swarmer Created', value: 0, icon: 'fa-solid fa-user-plus' },
        { label: 'Swarmer Online', value: 0, icon: 'fa-solid fa-wifi' },
        { label: 'Swarm Earned', value: '0', icon: 'fa-solid fa-coins' },
    ]);

    const [jobs, setJobs] = useState<Job[]>([]);
    const [godModeJobs, setGodModeJobs] = useState<Job[]>([]);
    const [swarmers, setSwarmers] = useState<Swarmer[]>([]);
    const [godModeSwarmers, setGodModeSwarmers] = useState<Swarmer[]>([]);

    // Fetch Metrics
    const fetchMetrics = async (walletAddress: string) => {
        try {
            const response = await fetch(`${backendUrl}/api/metrics/${walletAddress}`);
            if (response.ok) {
                const data = await response.json();
                setMetrics([
                    { label: 'Jobs Published', value: data.jobsPublished, icon: 'fa-solid fa-file-alt' },
                    { label: 'Jobs Completed', value: data.jobsCompleted, icon: 'fa-solid fa-check-circle' },
                    { label: 'Swarm Paid', value: data.swarmDistributed, icon: 'fa-solid fa-share-alt' },
                    { label: 'Swarmer Created', value: data.swarmerCreated, icon: 'fa-solid fa-user-plus' },
                    { label: 'Swarmer Online', value: data.swarmerOnline, icon: 'fa-solid fa-wifi' },
                    { label: 'Swarm Earned', value: data.swarmEarned, icon: 'fa-solid fa-coins' },
                ]);
            } else {
                console.error('Failed to fetch metrics:', await response.text());
            }
        } catch (error) {
            console.error('Error fetching metrics:', error);
        }
    };

    // Normalize Job Data
    const normalizeJob = (job: any): Job => ({
        id: job.job_id || job.target_id,
        type: job.target_id ? 'target' : 'job',
        platform: job.platform || 'x',
        target_url: job.tweet_url || job.target_url || '',
        creation_date: job.creation_date || 'Unknown date',
        totalRewards: job.jobRewards || job.totalRewards || job.reward || 0,
        rewardTokenTicker: job.rewardTokenTicker || '$swarm',
        rewardTokenAddress: job.rewardTokenAddress || 'Unknown address',
        boost: job.boost || '0',
        author_name: job.author_name || 'Unknown',
        tweet_text: job.tweet_text || 'No text available',
        instructions: (job.instructions || []).map((inst: any) => ({
            likes: inst.likes || inst.like || false,
            streams: inst.streams || false,
            retweets: inst.retweets || inst.retweet || false,
            comments: inst.comments || inst.comment || false,
            bookmarks: inst.bookmarks || inst.bookmark || false,
            follows: inst.follows || inst.follow || false,
            visits: inst.visits || inst.visit || false,
            claim: inst.claim || false,
        })),
        fundingWallet: job.fundingWallet || 'Unknown',
    });

    // Fetch Jobs
    const fetchJobs = async (walletAddress: string) => {
        try {
            const userJobsUrl = `${backendUrl}/api/jobs/${walletAddress}`;
            const godModeUrl = `${backendUrl}/api/jobs/g0dM0d3_1337_w4ll3t`;

            if (isGodModeWallet) {
                const godModeResponse = await fetch(godModeUrl);
                if (godModeResponse.ok) {
                    const data = await godModeResponse.json();
                    setGodModeJobs([...data.jobs.map(normalizeJob), ...data.targets.map(normalizeJob)]);
                } else {
                    console.error('Failed to fetch God Mode jobs:', await godModeResponse.text());
                }
            }

            const userResponse = await fetch(userJobsUrl);
            if (userResponse.ok) {
                const data = await userResponse.json();
                setJobs([...data.jobs.map(normalizeJob), ...data.targets.map(normalizeJob)]);
            } else {
                console.error('Failed to fetch user jobs:', await userResponse.text());
            }
        } catch (error) {
            console.error('Error fetching jobs:', error);
        }
    };

    // Fetch Swarmers
    const fetchSwarmers = async (walletAddress: string) => {
        try {
            const userSwarmersUrl = `${backendUrl}/api/swarmer-info/${walletAddress}`;
            const godModeUrl = `${backendUrl}/api/swarmer-info/g0dM0d3_1337_w4ll3t`;

            if (isGodModeWallet) {
                const godModeResponse = await fetch(godModeUrl);
                if (godModeResponse.ok) {
                    const data = await godModeResponse.json();
                    setGodModeSwarmers(data.swarmers || []);
                } else {
                    console.error('Failed to fetch God Mode swarmers:', await godModeResponse.text());
                }
            }

            const userResponse = await fetch(userSwarmersUrl);
            if (userResponse.ok) {
                const data = await userResponse.json();
                setSwarmers(data.swarmers || []);
            } else {
                console.error('Failed to fetch user swarmers:', await userResponse.text());
            }
        } catch (error) {
            console.error('Error fetching swarmers:', error);
        }
    };

    const handleDelete = async (id: string, type: string) => {
        try {
            const response = await fetch(`${backendUrl}/api/${type}/${id}`, { method: 'DELETE' });
            if (response.ok) {
                setJobs((prevJobs) => prevJobs.filter((job) => job.id !== id));
                setGodModeJobs((prevJobs) => prevJobs.filter((job) => job.id !== id));
            } else {
                console.error(`Failed to delete ${type}:`, await response.text());
            }
        } catch (error) {
            console.error('Error deleting job or target:', error);
        }
    };

    // Fetch Data on Load and Refresh
    useEffect(() => {
        if (connected && publicKey) {
            const walletAddress = publicKey.toBase58();
            fetchMetrics(walletAddress);
            fetchJobs(walletAddress);
            fetchSwarmers(walletAddress);

            const interval = setInterval(() => {
                fetchMetrics(walletAddress);
                fetchJobs(walletAddress);
                fetchSwarmers(walletAddress);
            }, 60000);

            return () => clearInterval(interval);
        }
    }, [connected, publicKey]);

    return (
        <div className="dashboard-page container">
            <h2>Swarmer Dashboard</h2>
            {connected ? (
                <>
                    <div className="dashmetrics-container">
                        {metrics.map((metric, index) => (
                            <div className="dashmetric-card" key={index}>
                                <i className={metric.icon + ' dashmetric-icon'} />
                                <div className="dashmetric-info">
                                    <p className="dashmetric-value">{metric.value}</p>
                                    <p className="dashmetric-label">{metric.label}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="tabs">
                        <button
                            className={`tab-button ${activeTab === 'jobs' ? 'active' : ''}`}
                            onClick={() => setActiveTab('jobs')}
                        >
                            Jobs
                        </button>
                        <button
                            className={`tab-button ${activeTab === 'swarmers' ? 'active' : ''}`}
                            onClick={() => setActiveTab('swarmers')}
                        >
                            Swarmers
                        </button>
                        {isGodModeWallet && (
                            <>
                                <button
                                    className={`tab-button ${activeTab === 'godMode' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('godMode')}
                                >
                                    God Mode - Jobs
                                </button>
                                <button
                                    className={`tab-button ${activeTab === 'godModeSwarmers' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('godModeSwarmers')}
                                >
                                    God Mode - Swarmers
                                </button>
                            </>
                        )}
                    </div>
                    <div className="tab-content">
                        {activeTab === 'jobs' ? (
                            <DashJobs jobs={jobs} onDelete={handleDelete} />
                        ) : activeTab === 'swarmers' ? (
                            <DashSwarmer swarmers={swarmers} />
                        ) : activeTab === 'godMode' ? (
                            <DashJobs jobs={godModeJobs} onDelete={handleDelete} />
                        ) : (
                            <DashSwarmer swarmers={godModeSwarmers} />
                        )}
                    </div>
                </>
            ) : (
                <p>Please connect your wallet to view the dashboard.</p>
            )}
        </div>
    );
};

export default Dashboard;
