// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swap-card {
    background: rgba(255, 255, 255, 0.05);
    padding: 20px;
    border-radius: 12px;
    width: 100%;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

    .swap-card:hover {
        transform: translateY(-3px);
        box-shadow: 0 6px 25px rgba(0, 245, 255, 0.9), 0 0 15px var(--accent-color);
    }

.card-header {
    display: flex;
    justify-content: space-between;
    color: var(--primary-color);
    font-weight: bold;
    margin-bottom: 10px;
}

.input-section {
    display: flex;
    align-items: center;
    gap: 10px;
}

.input-amount {
    width: 100%;
    padding: 10px;
    font-size: 1.5rem;
    border: none;
    background: transparent;
    color: var(--text-color);
    text-shadow: 0 0 6px var(--text-glow);
    outline: none;
}

.asset-name {
    font-size: 1.2rem;
    color: var(--accent-color);
    font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/SwapCard/SwapCard.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,yCAAyC;IACzC,qDAAqD;AACzD;;IAEI;QACI,2BAA2B;QAC3B,2EAA2E;IAC/E;;AAEJ;IACI,aAAa;IACb,8BAA8B;IAC9B,2BAA2B;IAC3B,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,YAAY;IACZ,uBAAuB;IACvB,wBAAwB;IACxB,qCAAqC;IACrC,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,0BAA0B;IAC1B,iBAAiB;AACrB","sourcesContent":[".swap-card {\r\n    background: rgba(255, 255, 255, 0.05);\r\n    padding: 20px;\r\n    border-radius: 12px;\r\n    width: 100%;\r\n    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7);\r\n    transition: transform 0.3s ease, box-shadow 0.3s ease;\r\n}\r\n\r\n    .swap-card:hover {\r\n        transform: translateY(-3px);\r\n        box-shadow: 0 6px 25px rgba(0, 245, 255, 0.9), 0 0 15px var(--accent-color);\r\n    }\r\n\r\n.card-header {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    color: var(--primary-color);\r\n    font-weight: bold;\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.input-section {\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 10px;\r\n}\r\n\r\n.input-amount {\r\n    width: 100%;\r\n    padding: 10px;\r\n    font-size: 1.5rem;\r\n    border: none;\r\n    background: transparent;\r\n    color: var(--text-color);\r\n    text-shadow: 0 0 6px var(--text-glow);\r\n    outline: none;\r\n}\r\n\r\n.asset-name {\r\n    font-size: 1.2rem;\r\n    color: var(--accent-color);\r\n    font-weight: bold;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
