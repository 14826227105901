import React, { useEffect, useState } from 'react';
import ReleaseSwarmModal, { ReleaseParams } from './ReleaseSwarmModal';
import {
    FaUserCircle,
    FaCheckCircle,
    FaUsers,
    FaUserFriends,
    FaClock,
    FaPlay,
    FaStop,
    FaTrash,
    FaTerminal,
    FaRocket,
} from 'react-icons/fa';
import './DashSwarmer.css';

export type Swarmer = {
    agent_id: string;
    handle?: string;
    status: string;
    last_connected: string;
    cycle_count?: number;
    X?: {
        profile_picture_url?: string;
        blue_tick?: boolean;
        followers_count?: number;
        following_count?: number;
        joined_date?: string
        handle?: string;
    };
};

type DashSwarmerProps = {
    swarmers: Swarmer[];
};

const DashSwarmer: React.FC<DashSwarmerProps> = ({ swarmers }) => {
    const [localProfiles, setLocalProfiles] = useState<Swarmer[]>([]);
    const [isApiReachable, setIsApiReachable] = useState(false);
    const [logModalProfileId, setLogModalProfileId] = useState<string | null>(null); // Current profile for logs
    const [logs, setLogs] = useState<string>('');
    const [isReleaseModalVisible, setIsReleaseModalVisible] = useState(false);
    const [isSwarmActive, setIsSwarmActive] = useState(false);


    // Fetch profiles from the local API
    useEffect(() => {
        const fetchLocalProfiles = async () => {
            try {
                const response = await fetch(`http://localhost:4269/profiles`);
                const data = await response.json();
                setLocalProfiles(Object.values(data.profiles));
                setIsApiReachable(true);
            } catch (error) {
                console.error('Failed to fetch local profiles:', error);
                setIsApiReachable(false);
            }
        };

        fetchLocalProfiles();
        const interval = setInterval(fetchLocalProfiles, 5000); // Poll every 5 seconds
        return () => clearInterval(interval);
    }, []);

    const handleAction = async (profileId: string, action: 'start' | 'stop' | 'delete') => {
        try {
            const response = await fetch(`http://localhost:4269/profile/${profileId}/${action}`, {
                method: action === 'delete' ? 'DELETE' : 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: action === 'start' ? JSON.stringify({ url: 'https://example.com' }) : undefined,
            });

            if (response.ok) {
                alert(`${action.charAt(0).toUpperCase() + action.slice(1)} action successful for ${profileId}`);
                // Refresh local profiles after action
                const profilesResponse = await fetch(`http://localhost:4269/profiles`);
                const profilesData = await profilesResponse.json();
                setLocalProfiles(Object.values(profilesData.profiles));
            } else {
                const errorData = await response.json();
                alert(`Failed to ${action} profile ${profileId}: ${errorData.error}`);
            }
        } catch (error) {
            console.error(`Error during ${action} action:`, error);
        }
    };

    const handleReleaseSwarm = async (params: ReleaseParams) => {
        try {
            console.log('Release Swarm Params:', params);
            const response = await fetch(`http://localhost:4269/profiles/maintenance`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(params),
            });

            if (response.ok) {
                alert('Swarm released successfully.');
                setIsReleaseModalVisible(false);
                setIsSwarmActive(true); // Set swarm as active
            } else {
                const errorData = await response.json();
                alert(`Failed to release swarm: ${errorData.error}`);
            }
        } catch (error) {
            console.error('Error during swarm release:', error);
        }
    };


    const fetchLogs = (profileId: string) => {
        setLogModalProfileId(profileId); // Open log modal
        setLogs(''); // Clear previous logs

        const eventSource = new EventSource(`http://localhost:4269/profile/${profileId}/logs`);

        eventSource.onmessage = (event) => {
            if (event.data.trim() !== "heartbeat") {
                setLogs((prevLogs) => prevLogs + event.data + '\n');
            }
        };

        eventSource.onerror = (err) => {
            console.error('Error with EventSource:', err);
            setLogs((prevLogs) => prevLogs + '\nConnection lost. Retrying...\n');
            eventSource.close(); // Close the old EventSource
            setTimeout(() => fetchLogs(profileId), 3000); // Retry after 3 seconds
        };

        // Cleanup on component unmount
        return () => {
            eventSource.close();
            console.log('EventSource connection closed');
        };
    };


    const closeLogModal = () => {
        setLogModalProfileId(null);
        setLogs('');
    };
    const getStatusColor = (status: string) => {
        switch (status.toLowerCase()) {
            case 'error':
                return '#ff4d4f'; // Bright red
            case 'working':
            case 'browsing':
            case 'swarmer_running':
                return '#52c41a'; // Bright green
            default:
                return '#1890ff'; // Bright blue
        }
    };


    const isMatchingProfile = (profileKey: string) => {
        console.log(`Checking if profile with key ${profileKey} matches any local profiles...`);

        // Check if the `profileKey` exists in local profiles
        const match = localProfiles.some((profile) => profile.profile_id === profileKey);

        if (match) {
            console.log(`Match found for profile key ${profileKey}`);
        } else {
            console.log(`No match found for profile key ${profileKey}`);
        }

        return match;
    };

    // Sort swarmers: matching profiles first, then by status 'working', and finally by last_connected
    const sortedSwarmers = [...swarmers].sort((a, b) => {
        const isAMatching = isMatchingProfile(a.profile_id);
        const isBMatching = isMatchingProfile(b.profile_id);

        if (isAMatching && !isBMatching) return -1; // Matching profiles come first
        if (!isAMatching && isBMatching) return 1;

        if (a.status === 'working' && b.status !== 'working') return -1; // Then sort by 'working' status
        if (b.status === 'working' && a.status !== 'working') return 1;

        return new Date(b.last_connected).getTime() - new Date(a.last_connected).getTime(); // Finally, sort by last connection date
    });

    return (
        <div className="dashswarmer-container">
            <div className="release-swarm-container">
                {isSwarmActive ? (
                    <button
                        className="release-swarm-button stop"
                        onClick={async () => {
                            console.log('Stop Swarm button clicked');
                            try {
                                const response = await fetch(`http://localhost:4269/profiles/maintenance/stop`, {
                                    method: 'POST',
                                });
                                if (response.ok) {
                                    alert('Swarm stopped successfully.');
                                    setIsSwarmActive(false); // Set swarm as inactive
                                } else {
                                    const errorData = await response.json();
                                    alert(`Failed to stop swarm: ${errorData.error}`);
                                }
                            } catch (error) {
                                console.error('Error stopping swarm:', error);
                            }
                        }}
                    >
                        <FaStop /> Stop Swarm
                    </button>
                ) : (
                    <button
                        className="release-swarm-button"
                        onClick={() => {
                            console.log('Release Swarm button clicked');
                            setIsReleaseModalVisible(true);
                        }}
                    >
                        <FaRocket /> Release Swarm
                    </button>
                )}
            </div>
            {!isApiReachable && <p>Local API is not reachable. Ensure the software is running locally.</p>}
            {sortedSwarmers.map((swarmer, index) => (
                <div className="swarmer-item" key={index}>
                    <div
                        className="status-indicator"
                        style={{ backgroundColor: getStatusColor(swarmer.status) }}
                    ></div>
                    <div className="profile-section">
                        {swarmer.X?.profile_picture_url ? (
                            <div className="profile-picture-container">
                                <img
                                    className="profile-picture"
                                    src={swarmer.X.profile_picture_url}
                                    alt={`${swarmer.X.handle || 'Agent'} profile`}
                                />
                            </div>
                        ) : (
                            <FaUserCircle className="default-profile-icon" />
                        )}
                    </div>
                    <div className="swarmer-details">
                        <div className="swarmer-main-info">
                            <span className="swarmer-agent-id">{swarmer.profile_id}</span>
                            {swarmer.X?.handle ? (
                                <span className="swarmer-handle">({swarmer.X.handle})</span>
                            ) : (
                                <span className="swarmer-handle-placeholder">(No Handle)</span>
                            )}
                            {swarmer.X?.blue_tick && <FaCheckCircle className="blue-tick" />}
                        </div>
                        <div className="swarmer-follow-stats">
                            <FaUserFriends className="icon" /> {swarmer.X?.following_count || 0} |{' '}
                            <FaUsers className="icon" /> {swarmer.X?.followers_count || 0}
                        </div>
                    </div>
                    <div className="swarmer-extra-info">
                        <span className="swarmer-cycle-count">Level: {swarmer.cycle_count || 0}</span>
                        <div className="swarmer-last-connected">
                            <FaClock className="icon" />{' '}
                            {new Date(swarmer.last_connected).toLocaleString()}
                        </div>
                    </div>
                    {isMatchingProfile(swarmer.profile_id) && (
                        <div className="swarmer-bar">
                            <button
                                className="swarmer-button start"
                                onClick={() => handleAction(swarmer.profile_id, 'start')}
                            >
                                <FaPlay /> 
                            </button>
                            <button
                                className="swarmer-button stop"
                                onClick={() => handleAction(swarmer.profile_id, 'stop')}
                            >
                                <FaStop /> 
                            </button>
                            <button
                                className="swarmer-button delete"
                                onClick={() => handleAction(swarmer.profile_id, 'delete')}
                            >
                                <FaTrash /> 
                            </button>
                            <button
                                className="swarmer-button logs"
                                onClick={() => fetchLogs(swarmer.agent_id)}
                            >
                                <FaTerminal />
                            </button>
                        </div>
                    )}
                </div>
            ))}
            {logModalProfileId && (
                <div className="log-modal">
                    <div className="log-modal-content">
                        <h3>Logs for {logModalProfileId}</h3>
                        <div className="log-container">{logs || 'Loading logs...'}</div>
                        <button onClick={closeLogModal}>Close</button>
                    </div>
                </div>
            )}

            <ReleaseSwarmModal
                isVisible={isReleaseModalVisible}
                onClose={() => setIsReleaseModalVisible(false)}
                onConfirm={(params) => handleReleaseSwarm(params)}
            />
        </div>
    );
};

export default DashSwarmer;