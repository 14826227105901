import React, { useState, useEffect } from 'react';
import './BoostPopup.css';
import { Connection, PublicKey, Transaction } from '@solana/web3.js';
import { getAccount, getMint, createBurnCheckedInstruction } from '@solana/spl-token';
import { useWallet } from '@solana/wallet-adapter-react';

type BoostPopupProps = {
    type: string;
    id: string;
    onClose: () => void;
};

const BoostPopup: React.FC<BoostPopupProps> = ({ type, id, onClose }) => {
    const [rank, setRank] = useState<number | null>(null);
    const [topBoost, setTopBoost] = useState<number | null>(null);
    const [currentBoost, setCurrentBoost] = useState<number>(0);
    const [boostValue, setBoostValue] = useState<number>(0);
    const [decimals, setDecimals] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const { publicKey, sendTransaction } = useWallet();
    const connection = new Connection('https://chaotic-crimson-star.solana-mainnet.quiknode.pro/a7cdf02f65bc61c5443446e5db4ea0fda4cd455e');

    const TOKEN_MINT = new PublicKey('6NPE8BZbY71L3EArLLjF2KLtJL7sJmYfysyMtHfEJkpW');
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:42069';

    useEffect(() => {
        const fetchTokenDecimals = async () => {
            try {
                const mintInfo = await getMint(connection, TOKEN_MINT);
                setDecimals(mintInfo.decimals);
            } catch (error) {
                console.error('Error fetching token decimals:', error);
            }
        };

        const fetchRanking = async () => {
            try {
                const response = await fetch(`${backendUrl}/api/ranking?type=${type}&id=${id}`);
                const data = await response.json();
                setRank(data.rank);
                setTopBoost(data.topBoost || 0);
                setCurrentBoost(data.currentBoost || 0);
            } catch (error) {
                console.error('Error fetching ranking:', error);
                setRank(null);
                setTopBoost(null);
                setCurrentBoost(0);
            }
        };

        fetchTokenDecimals();
        fetchRanking();
    }, [type, id, connection]);

    const handleBurnTransaction = async () => {
        if (!publicKey) {
            alert('Please connect your wallet to proceed.');
            return;
        }

        try {
            setLoading(true);

            // Calculate the burn amount in smallest units
            const burnAmount = boostValue * 10 ** decimals;

            // Find associated token account
            const tokenAccounts = await connection.getParsedTokenAccountsByOwner(publicKey, { mint: TOKEN_MINT });
            const tokenAccount = tokenAccounts.value.find((accountInfo) =>
                accountInfo.account.data.parsed.info.tokenAmount.uiAmount > 0
            );

            if (!tokenAccount) {
                alert('No token account found for burning. Please ensure you have the tokens in your wallet.');
                return;
            }

            const tokenAccountPubkey = new PublicKey(tokenAccount.pubkey);

            // Create burn instruction
            const transaction = new Transaction().add(
                createBurnCheckedInstruction(
                    tokenAccountPubkey,
                    TOKEN_MINT,
                    publicKey,
                    burnAmount,
                    decimals
                )
            );

            // Send transaction
            const signature = await sendTransaction(transaction, connection);
            await connection.confirmTransaction(signature, 'processed');

            // Call the boost API after confirmation
            await fetch('/api/boost', {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id,
                    boost: boostValue,
                }),
            });

            alert('Boost applied successfully!');
        } catch (error) {
            console.error('Error processing burn transaction:', error);
            alert('Transaction failed. Please try again.');
        } finally {
            setLoading(false);
            onClose();
        }
    };

    return (
        <div className="boost-popup">
            <div className="boost-popup-content">
                <button className="close-button" onClick={onClose}>
                    ✖
                </button>
                <h3>Boost Your Target</h3>
                <div className="boost-info">
                    <p>
                        <strong>Rank:</strong> {rank !== null ? rank : 'Loading...'}
                    </p>
                    <p>
                        <strong>Top Boost:</strong> {topBoost !== null ? `${topBoost} $swarm` : 'Loading...'}
                    </p>
                    <p>
                        <strong>Current Boost:</strong> {currentBoost} $swarm
                    </p>
                </div>
                <p>Enter the number of $swarm tokens to burn to boost your target.</p>
                <input
                    type="number"
                    value={boostValue}
                    onChange={(e) => setBoostValue(parseInt(e.target.value, 10))}
                    placeholder="Tokens to burn"
                    className="boost-input"
                />
                <button
                    className="boost-confirm-button"
                    onClick={handleBurnTransaction}
                    disabled={loading || boostValue <= 0}
                >
                    {loading ? 'Processing...' : 'Burn to Boost'}
                </button>
            </div>
        </div>
    );
};

export default BoostPopup;
