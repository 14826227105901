import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaUser, FaComments, FaLock, FaLockOpen, FaEye } from 'react-icons/fa';
import './BankItem.css';

interface Bank {
    id: string;
    name: string;
    creatorAddress: string;
    isPublic: boolean;
    imageUrl: string | null | undefined; // Allow null or undefined for safety
    commentCount: number;
}

interface BankItemProps {
    bank: Bank;
}

const truncateAddress = (address: string) => `${address.slice(0, 6)}...${address.slice(-4)}`;

const isVideoFile = (url: string | null | undefined): boolean => {
    if (!url) return false;
    const videoExtensions = ['mp4', 'webm', 'mov', 'avi', 'mkv'];
    const fileExtension = url.split('.').pop()?.toLowerCase();
    return videoExtensions.includes(fileExtension || '');
};

const BankItem: React.FC<BankItemProps> = ({ bank }) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.muted = true; // Ensure video is muted for autoplay
            videoRef.current.play().catch((error) => {
                console.error('Autoplay failed:', error);
            });
        }
    }, [bank.imageUrl]); // Ensure the effect runs when the imageUrl changes

    return (
        <div className="bank-item">
            <div className="bank-media">
                {bank.imageUrl ? (
                    isVideoFile(bank.imageUrl) ? (
                        <video
                            ref={videoRef}
                            src={bank.imageUrl}
                            className="bank-video"
                            autoPlay
                            loop
                            muted
                            playsInline
                        />
                    ) : (
                        <img
                            src={bank.imageUrl}
                            alt={`${bank.name} Media`}
                            className="bank-image"
                            onError={(e) => (e.currentTarget.style.display = 'none')}
                        />
                    )
                ) : (
                    <div className="placeholder-media">No media available</div>
                )}
            </div>

            <div className="bank-info-inline">
                <h3 className="bank-name">{bank.name}</h3>
                <p className="bank-uuid">UUID: {bank.id}</p>

                <div className="info-row">
                    <FaUser className="icon" title="Creator" />
                    <span>{truncateAddress(bank.creatorAddress)}</span>
                </div>

                <div className="info-row">
                    <FaComments className="icon" title="Comments" />
                    <span>{bank.commentCount}</span>
                </div>

                <div className="info-row">
                    {bank.isPublic ? (
                        <FaLockOpen className="icon" title="Public Bank" />
                    ) : (
                        <FaLock className="icon" title="Private Bank" />
                    )}
                    <span>{bank.isPublic ? 'Public' : 'Private'}</span>
                </div>

                <Link to={`/bank/${bank.id}`} className="view-button">
                    <FaEye className="button-icon" /> View
                </Link>
            </div>
        </div>
    );
};

export default BankItem;
