import React, { useState, useEffect } from 'react';
import { FaFileUpload, FaTimes, FaPenNib } from 'react-icons/fa';
import { useWallet } from '@solana/wallet-adapter-react';
import './Customs.css';

const GOD_MODE_WALLETS = (process.env.REACT_APP_GOD_MODE_WALLETS || '')
    .split(',')
    .map((wallet) => wallet.trim());

const Customs: React.FC = () => {
    const [activeTab, setActiveTab] = useState<'bio' | 'banner' | 'pfp'>('bio');
    const [bio, setBio] = useState<string>('');
    const [banner, setBanner] = useState<File | null>(null);
    const [pfp, setPfp] = useState<File | null>(null);
    const [customs, setCustoms] = useState<any[]>([]);
    const [statusMessage, setStatusMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

    const { publicKey } = useWallet();
    const walletAddress = publicKey?.toBase58();

    const MAX_FILE_SIZE = 5 * 1024 * 1024;

    // Check if the wallet is authorized for God Mode
    useEffect(() => {
        if (walletAddress && GOD_MODE_WALLETS.includes(walletAddress)) {
            setIsAuthorized(true);
        } else {
            setIsAuthorized(false);
        }
    }, [walletAddress]);

    // Fetch existing customs on wallet change or tab change
    useEffect(() => {
        if (!isAuthorized || !walletAddress) return;

        const fetchCustoms = async () => {
            try {
                const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:42069';
                const response = await fetch(`${backendUrl}/api/customs/${activeTab}`);
                if (!response.ok) throw new Error(`Failed to fetch customs: ${response.statusText}`);
                const data = await response.json();
                setCustoms(data.customs);
            } catch (error) {
                console.error('Error fetching customs:', error);
            }
        };

        fetchCustoms();
    }, [walletAddress, activeTab, isAuthorized]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, type: 'banner' | 'pfp') => {
        const file = event.target.files?.[0];
        if (file && file.size > MAX_FILE_SIZE) {
            setErrorMessage('File size must be less than 5MB.');
            if (type === 'banner') setBanner(null);
            if (type === 'pfp') setPfp(null);
        } else {
            setErrorMessage(null);
            if (type === 'banner') setBanner(file || null);
            if (type === 'pfp') setPfp(file || null);
        }
    };

    const handleDelete = async (customId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this custom?");
        if (!confirmDelete) return;

        try {
            const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:42069';
            const response = await fetch(`${backendUrl}/api/customs/delete`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: customId }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to delete custom: ${errorText}`);
            }

            setCustoms((prev) => prev.filter((custom) => custom._id !== customId));
            setStatusMessage("Custom deleted successfully.");
        } catch (error) {
            console.error("Error deleting custom:", error);
            setErrorMessage("Failed to delete custom. Please try again.");
        }
    };

    const handleSubmit = async () => {
        if (!walletAddress) {
            setStatusMessage('Wallet not connected. Please connect your wallet.');
            return;
        }

        const formData = new FormData();
        formData.append('walletAddress', walletAddress);
        formData.append('type', activeTab);

        if (activeTab === 'bio') {
            if (!bio.trim()) {
                setStatusMessage('Please provide a bio.');
                return;
            }
            formData.append('data', bio.trim());
        } else if (activeTab === 'banner' && banner) {
            formData.append('data', banner);
        } else if (activeTab === 'pfp' && pfp) {
            formData.append('data', pfp);
        } else {
            setStatusMessage('Please provide valid data.');
            return;
        }

        setStatusMessage('Uploading custom content...');
        try {
            const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:42069';
            const response = await fetch(`${backendUrl}/api/customs/add`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error(`Backend response: ${errorText}`);
                throw new Error(`Failed to add custom content: ${response.statusText}`);
            }

            const data = await response.json();
            setStatusMessage(`Custom ${activeTab} added successfully.`);
            setCustoms((prev) => [...prev, data.custom]);
        } catch (error) {
            console.error('Error adding custom content:', error);
            setStatusMessage('Failed to add custom content. Please try again.');
        }
    };

    if (!isAuthorized) {
        return (
            <div className="customs-container">
                <h1 className="customs-header">
                    Access Denied
                </h1>
                <p className="error-message">You do not have permission to access this page.</p>
            </div>
        );
    }

    return (
        <div className="customs-container">
            <h1 className="customs-header">
                <FaPenNib /> Manage Your Customs
            </h1>
            <div className="tabs">
                {['bio', 'banner', 'pfp'].map((tab) => (
                    <button
                        key={tab}
                        className={activeTab === tab ? 'active' : ''}
                        onClick={() => setActiveTab(tab as 'bio' | 'banner' | 'pfp')}
                    >
                        {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </button>
                ))}
            </div>

            <div className="tab-content">
                {activeTab === 'bio' ? (
                    <textarea
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                        placeholder="Write your bio here..."
                        className="bio-input"
                    />
                ) : (
                    <div className="file-upload">
                        <label>
                            {!(activeTab === 'banner' ? banner : pfp) ? (
                                <div className={`preview-x-format ${activeTab}`}>
                                    <FaFileUpload />
                                    <span>Upload {activeTab === 'banner' ? 'Banner' : 'PFP'}</span>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) =>
                                            handleFileChange(
                                                e,
                                                activeTab === 'banner' ? 'banner' : 'pfp'
                                            )
                                        }
                                    />
                                </div>
                            ) : (
                                <div className={`media-preview-x ${activeTab}`}>
                                    <img
                                        src={
                                            activeTab === 'banner'
                                                ? URL.createObjectURL(banner)
                                                : URL.createObjectURL(pfp)
                                        }
                                        alt={`${activeTab} Preview`}
                                    />
                                </div>
                            )}
                        </label>
                        {(banner || pfp) && (
                            <button
                                className="remove-file"
                                onClick={() => (activeTab === 'banner' ? setBanner(null) : setPfp(null))}
                            >
                                <FaTimes />
                            </button>
                        )}
                    </div>
                )}
            </div>

            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {statusMessage && <p className="status-message">{statusMessage}</p>}

            <button className="submit-button" onClick={handleSubmit}>
                Save {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}
            </button>

            <h2 className="existing-header">
                Your Existing {activeTab === 'bio' ? 'Bios' : activeTab === 'banner' ? 'Banners' : 'PFPs'}
            </h2>
            <div className="existing-customs">
                {customs
                    .filter((custom) => custom.type === activeTab)
                    .map((custom, index) => (
                        <div key={index} className="custom-item">
                            {custom.type === 'bio' ? (
                                <textarea className="custom-bio" readOnly value={custom.data} />
                            ) : (
                                <div className={`media-preview-x ${custom.type}`}>
                                    <img src={custom.data} alt={`${custom.type} Preview`} />
                                </div>
                            )}
                            <p className="usage-count">Used {custom.usageCount} times</p>
                            <button
                                className="delete-button"
                                onClick={() => handleDelete(custom._id)}
                            >
                                Delete
                            </button>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default Customs;
