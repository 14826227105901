import React, { useState } from 'react';
import { FaPlus, FaFileUpload, FaTimes, FaCommentDots } from 'react-icons/fa';
import SolanaButton from '../Common/SolanaButton';
import { useWallet } from '@solana/wallet-adapter-react';
import './AddCommentModal.css';

interface AddCommentModalProps {
    bankId: string;
    onClose: () => void;
    onCommentAdded: (comments: any[]) => void;
}

interface BulkComment {
    text: string;
    file: File | null;
}

const AddCommentModal: React.FC<AddCommentModalProps> = ({ bankId, onClose, onCommentAdded }) => {
    const [activeTab, setActiveTab] = useState<'single' | 'bulk'>('single');
    const [singleComment, setSingleComment] = useState<string>('');
    const [singleFile, setSingleFile] = useState<File | null>(null);
    const [bulkComments, setBulkComments] = useState<BulkComment[]>([{ text: '', file: null }]);
    const [statusMessage, setStatusMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const { publicKey } = useWallet();
    const creatorAddress = publicKey?.toBase58();
    const MAX_FILE_SIZE = 5 * 1024 * 1024;

    const handleSingleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && file.size > MAX_FILE_SIZE) {
            setErrorMessage('File size must be less than 5MB.');
            setSingleFile(null);
        } else {
            setErrorMessage(null);
            setSingleFile(file || null);
        }
    };

    const handleBulkFileChange = (index: number, file: File | null) => {
        const updatedBulk = [...bulkComments];
        updatedBulk[index].file = file;
        setBulkComments(updatedBulk);
    };

    const handleBulkTextChange = (index: number, text: string) => {
        const updatedBulk = [...bulkComments];
        updatedBulk[index].text = text;
        setBulkComments(updatedBulk);
    };

    const addBulkCommentRow = () => {
        setBulkComments([...bulkComments, { text: '', file: null }]);
    };

    const removeBulkCommentRow = (index: number) => {
        setBulkComments(bulkComments.filter((_, i) => i !== index));
    };

    const handleCommentSubmit = async (signature: string) => {
        if (!creatorAddress) {
            setStatusMessage('Wallet not connected. Please connect your wallet.');
            return;
        }

        let comments;
        const formData = new FormData();

        if (activeTab === 'single') {
            if (!singleComment.trim() && !singleFile) {
                setStatusMessage('Please provide text or upload media for the comment.');
                return;
            }

            comments = [
                {
                    text: singleComment.trim(),
                    categoryId: bankId,
                    creatorAddress,
                },
            ];
            if (singleFile) formData.append('media', singleFile);
        } else {
            if (bulkComments.some((c) => !c.text.trim())) {
                setStatusMessage('Please provide text for all bulk comments.');
                return;
            }

            comments = bulkComments.map((c) => ({
                text: c.text.trim(),
                categoryId: bankId,
                creatorAddress,
            }));
            bulkComments.forEach((c, index) => {
                if (c.file) formData.append(`media-${index}`, c.file);
            });
        }

        formData.append('comments', JSON.stringify(comments));
        formData.append('signature', signature);

        setStatusMessage('Publishing comment(s)...');
        try {
            const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:42069';
            const response = await fetch(`${backendUrl}/api/comments/bulk`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) throw new Error(`Failed to create comments: ${response.statusText}`);
            const data = await response.json();

            setStatusMessage('Comment(s) added');
            setTimeout(() => {
                onCommentAdded(data.comments);
                onClose();
            }, 2000);
        } catch (error) {
            console.error('Error creating comments:', error);
            setStatusMessage('Failed to create comments. Please try again.');
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2 className="modal-header">
                    <FaCommentDots /> Add Comment
                </h2>
                <div className="tabs">
                    <button
                        className={activeTab === 'single' ? 'active' : ''}
                        onClick={() => setActiveTab('single')}
                    >
                        Single
                    </button>
                    <button
                        className={activeTab === 'bulk' ? 'active' : ''}
                        onClick={() => setActiveTab('bulk')}
                    >
                        Bulk
                    </button>
                </div>

                {activeTab === 'single' && (
                    <div className="tab-content">
                        <div className="single-comment-container">
                            <textarea
                                value={singleComment}
                                onChange={(e) => setSingleComment(e.target.value)}
                                placeholder="Write your comment here..."
                            />
                            <div className="file-upload">
                                <label>
                                    {!singleFile ? (
                                        <>
                                            <FaFileUpload />
                                            <span>Upload Media</span>
                                            <input
                                                type="file"
                                                accept="image/*,video/*"
                                                onChange={handleSingleFileChange}
                                            />
                                        </>
                                    ) : (
                                        <div className="media-preview">
                                            {singleFile.type.startsWith('image') ? (
                                                <img
                                                    src={URL.createObjectURL(singleFile)}
                                                    alt="Uploaded Preview"
                                                    className="preview-img"
                                                />
                                            ) : (
                                                <video
                                                    src={URL.createObjectURL(singleFile)}
                                                    controls
                                                    className="preview-video"
                                                />
                                            )}
                                        </div>
                                    )}
                                </label>
                                {singleFile && (
                                    <button
                                        className="remove-file"
                                        onClick={() => setSingleFile(null)}
                                    >
                                        <FaTimes />
                                    </button>
                                )}
                            </div>
                        </div>
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                    </div>
                )}


                {activeTab === 'bulk' && (
                    <div className="tab-content">
                        {bulkComments.map((comment, index) => (
                            <div key={index} className="bulk-comment-row">
                                <textarea
                                    value={comment.text}
                                    onChange={(e) => handleBulkTextChange(index, e.target.value)}
                                    placeholder="Comment text..."
                                />
                                <div className="file-upload">
                                    <label>
                                        {!comment.file ? (
                                            <>
                                                <FaFileUpload />
                                                <span>Upload Media</span>
                                                <input
                                                    type="file"
                                                    accept="image/*,video/*"
                                                    onChange={(e) =>
                                                        handleBulkFileChange(index, e.target.files?.[0] || null)
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <div className="media-preview">
                                                {comment.file.type.startsWith('image') ? (
                                                    <img
                                                        src={URL.createObjectURL(comment.file)}
                                                        alt="Uploaded Preview"
                                                        className="preview-img"
                                                    />
                                                ) : (
                                                    <video
                                                        src={URL.createObjectURL(comment.file)}
                                                        controls
                                                        className="preview-video"
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </label>
                                    {comment.file && (
                                        <button
                                            className="remove-file"
                                            onClick={() => handleBulkFileChange(index, null)}
                                        >
                                            <FaTimes />
                                        </button>
                                    )}
                                </div>
                                <button
                                    className="remove-row"
                                    onClick={() => removeBulkCommentRow(index)}
                                >
                                    <FaTimes />
                                </button>
                            </div>
                        ))}
                        <button className="add-row" onClick={addBulkCommentRow}>
                            <FaPlus /> Add Row
                        </button>
                    </div>
                )}

                {statusMessage && <p className="status-message">{statusMessage}</p>}
                <SolanaButton
                    type="burn"
                    amount={10}
                    tokenMintAddress="6NPE8BZbY71L3EArLLjF2KLtJL7sJmYfysyMtHfEJkpW"
                    onTransactionComplete={handleCommentSubmit}
                />
                <button className="close-modal-button" onClick={onClose}>
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default AddCommentModal;
