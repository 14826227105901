import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaBullseye, FaComments, FaChartLine, FaCoins, FaDove, FaPalette } from 'react-icons/fa';
import './VerticalNav.css';

// Utility function to check if a wallet is a God Mode wallet
const isGodModeWallet = (publicKey: string | null): boolean => {
    const GOD_MODE_WALLETS = (process.env.REACT_APP_GOD_MODE_WALLETS || '')
        .split(',')
        .map((wallet) => wallet.trim());
    return !!publicKey && GOD_MODE_WALLETS.includes(publicKey);
};

interface VerticalNavProps {
    publicKey: string | null; // Wallet public key for the current user
}

const VerticalNav: React.FC<VerticalNavProps> = ({ publicKey }) => {
    const location = useLocation();

    // Helper function to determine if a nav link is active
    const isActive = (path: string) => location.pathname === path;

    return (
        <div className="vertical-nav">
            <Link
                to="/"
                className={`nav-icon-wrapper ${isActive('/') ? 'active' : ''}`}
                data-tooltip="Home"
            >
                <div className="icon-circle">
                    <FaHome className="nav-icon" />
                </div>
            </Link>
            <Link
                to="/buy-swarm"
                className={`nav-icon-wrapper ${isActive('/buy-swarm') ? 'active' : ''}`}
                data-tooltip="Swap"
            >
                <div className="icon-circle">
                    <FaCoins className="nav-icon" />
                </div>
            </Link>
            <Link
                to="/create-job"
                className={`nav-icon-wrapper ${isActive('/create-job') ? 'active' : ''}`}
                data-tooltip="Publish"
            >
                <div className="icon-circle">
                    <FaBullseye className="nav-icon" />
                </div>
            </Link>
            <Link
                to="/comment-bank"
                className={`nav-icon-wrapper ${isActive('/comment-bank') ? 'active' : ''}`}
                data-tooltip="Comment"
            >
                <div className="icon-circle">
                    <FaComments className="nav-icon" />
                </div>
            </Link>
            <Link
                to="/dashboard"
                className={`nav-icon-wrapper ${isActive('/dashboard') ? 'active' : ''}`}
                data-tooltip="Monitor"
            >
                <div className="icon-circle">
                    <FaChartLine className="nav-icon" />
                </div>
            </Link>
            <a
                href="https://github.com/XSwarm-dev/XSwarm"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-icon-wrapper"
                data-tooltip="Deploy Swarmers"
            >
                <div className="icon-circle">
                    <FaDove className="nav-icon" />
                </div>
            </a>

            {/* Conditionally render the Customs menu for God Mode wallets */}
            {isGodModeWallet(publicKey) && (
                <Link
                    to="/customs"
                    className={`nav-icon-wrapper ${isActive('/customs') ? 'active' : ''}`}
                    data-tooltip="Customs"
                >
                    <div className="icon-circle">
                        <FaPalette className="nav-icon" />
                    </div>
                </Link>
            )}
        </div>
    );
};

export default VerticalNav;
