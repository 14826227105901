import React, { useState } from 'react';
import { LinearProgress } from '@mui/material';
import './DashJobs.css';
import BoostPopup from './BoostPopup'; // Import the BoostPopup component

export type Job = {
    id: string;
    type: string; // Either 'job' or 'target'
    platform: string;
    target_url: string;
    creation_date: string;
    totalRewards: number;
    rewardTokenTicker: string;
    queuePosition?: number;
    queueLength?: number;
    instructions: {
        likes?: boolean;
        streams?: boolean;
        retweets?: boolean;
        comments?: boolean;
        bookmarks?: boolean;
        follows?: boolean;
        visits?: boolean;
        claim?: boolean;
    }[];
};

type DashJobsProps = {
    jobs: Job[];
    onDelete: (id: string, type: string) => void;

};

const DashJobs: React.FC<DashJobsProps> = ({ jobs, onDelete }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [boostingJob, setBoostingJob] = useState<Job | null>(null);

    const aggregateReactions = (instructions: Job['instructions']) => {
        const reactionKeys = ['streams', 'likes', 'retweets', 'bookmarks', 'comments', 'follows', 'visits'] as const;

        return reactionKeys.reduce(
            (acc, key) => {
                acc[key] = {
                    total: instructions.filter((inst) => inst[key] === true).length,
                    completed: instructions.filter((inst) => inst[key] === true && inst.claim).length,
                };
                return acc;
            },
            {} as Record<typeof reactionKeys[number], { total: number; completed: number }>
        );
    };

    const extractHandle = (url: string) => {
        try {
            const urlObj = new URL(url);

            // Handle SoundCloud
            if (urlObj.hostname === 'soundcloud.com') {
                return urlObj.pathname.split('/').pop() || url;
            }

            // Handle YouTube
            if (urlObj.hostname === 'www.youtube.com' || urlObj.hostname === 'youtube.com') {
                return urlObj.searchParams.get('v') || url;
            }
            if (urlObj.hostname === 'youtu.be') {
                return urlObj.pathname.slice(1); // Remove leading slash
            }

            // Handle X (Twitter)
            if (urlObj.hostname === 'x.com') {
                return urlObj.pathname.split('/')[1] || url;
            }

            return url; // Fallback for unsupported URLs
        } catch (error) {
            console.error('Invalid URL:', url, error);
            return url; // Fallback in case of invalid URL
        }
    };

    const handleBoostClick = (job: Job) => {
        setBoostingJob(job);
        setShowPopup(true);
    };

    const handleTrashClick = (job: Job) => {
        if (window.confirm(`Are you sure you want to delete this ${job.type}?`)) {
            onDelete(job.id, job.type);
        }
    };

    // Sort jobs by creation_date (newest to oldest)
    const sortedJobs = [...jobs].sort(
        (a, b) => new Date(b.creation_date).getTime() - new Date(a.creation_date).getTime()
    );

    return (
        <div className="dashjobs-container">
            {sortedJobs.map((job) => {
                const reactionTotals = aggregateReactions(job.instructions);
                const overallProgress = job.instructions.length
                    ? Math.round(
                        (job.instructions.filter((inst) => inst.claim).length / job.instructions.length) * 100
                    )
                    : 0;
                const queueDisplay = job.queuePosition
                    ? `${job.queuePosition}/${job.queueLength || '∞'}`
                    : '';

                return (
                    <div className="job-item" key={job.id}>
                        <div className="job-column">
                            <div className="platform-icon">
                                <i className={`fa-brands fa-${job.platform}`} />
                            </div>
                        </div>
                        <div className="job-column">
                            <div className="job-url">
                                <a href={job.target_url} target="_blank" rel="noopener noreferrer">
                                    <i className="fa-solid fa-link url-icon"></i> {extractHandle(job.target_url)}
                                </a>
                            </div>
                            <div className="job-date">
                                {new Date(job.creation_date).toLocaleDateString()}
                            </div>
                        </div>
                        <div className="job-column">
                            <div className="job-rewards">
                                <i className="fa-solid fa-coins rewards-icon"></i>
                                <span className="rewards-amount">
                                    {job.totalRewards} {job.rewardTokenTicker}
                                </span>
                            </div>
                        </div>
                        <div className="job-column">
                            <div className="job-reactions">
                                {Object.entries(reactionTotals)
                                    .filter(([_, data]) => data.total > 0)
                                    .map(([reaction, data]) => (
                                        <div className="reaction-item" key={reaction}>
                                            <i
                                                className={`fa-solid ${reaction === 'likes'
                                                    ? 'fa-thumbs-up'
                                                    : reaction === 'retweets'
                                                        ? 'fa-retweet'
                                                        : reaction === 'bookmarks'
                                                            ? 'fa-bookmark'
                                                            : reaction === 'comments'
                                                                ? 'fa-comment'
                                                                : reaction === 'follows'
                                                                    ? 'fa-user-plus'
                                                                    : reaction === 'streams'
                                                                        ? 'fa-play-circle'
                                                                        : 'fa-eye'
                                                    }`}
                                            />
                                            <div className="reaction-count">
                                                {data.completed}/{data.total}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                            <LinearProgress
                                variant="determinate"
                                value={overallProgress}
                                className="mui-linear-progress"
                            />
                        </div>
                        <div className="job-actions">
                            <div className="queue-position">{queueDisplay}</div>
                            {job.type === 'target' && overallProgress < 100 && (
                                <button
                                    className="action-button boost-button"
                                    onClick={() => handleBoostClick(job)}
                                >
                                    Boost
                                </button>
                            )}
                            <button className="action-button">
                                <i className="fa-solid fa-pause" />
                            </button>
                            <button className="action-button" onClick={() => handleTrashClick(job)}>
                                <i className="fa-solid fa-trash" />
                            </button>
                        </div>
                    </div>
                );
            })}
            {showPopup && boostingJob && (
                <BoostPopup
                    type={boostingJob.type}
                    id={boostingJob.id}
                    onClose={() => setShowPopup(false)}
                />
            )}
        </div>
    );
};

export default DashJobs;
