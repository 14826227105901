// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bank-list {
    display: flex; /* Default layout for desktop */
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
}

/* Mobile-first grid layout */
@media (max-width: 768px) {
    .bank-list {
        display: grid; /* Switch to grid layout on mobile */
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 15px;
        padding: 0 10px; /* Add padding for better spacing */
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/BankList/BankList.css"],"names":[],"mappings":"AAAA;IACI,aAAa,EAAE,+BAA+B;IAC9C,sBAAsB;IACtB,SAAS;IACT,gBAAgB;AACpB;;AAEA,6BAA6B;AAC7B;IACI;QACI,aAAa,EAAE,oCAAoC;QACnD,2DAA2D;QAC3D,SAAS;QACT,eAAe,EAAE,mCAAmC;IACxD;AACJ","sourcesContent":[".bank-list {\r\n    display: flex; /* Default layout for desktop */\r\n    flex-direction: column;\r\n    gap: 15px;\r\n    margin-top: 20px;\r\n}\r\n\r\n/* Mobile-first grid layout */\r\n@media (max-width: 768px) {\r\n    .bank-list {\r\n        display: grid; /* Switch to grid layout on mobile */\r\n        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\r\n        gap: 15px;\r\n        padding: 0 10px; /* Add padding for better spacing */\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
