import React from 'react';
import { FaTasks, FaUsers } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router
import './ExplanationBlock.css';

const ExplanationBlock: React.FC = () => {
    const navigate = useNavigate();

    const handleGetStarted = () => {
        navigate('/create-job'); // Redirect to the create-job page
    };

    const handleJoinNow = () => {
        window.open('https://github.com/XSwarm-dev/XSwarm', '_blank'); // Open GitHub in a new tab
    };

    return (
        <div className="explanation-section">
            <div className="explanation-content">
                <div className="explanation-text">
                    <h1><span className="highlight">Discover XSwarm</span></h1>
                    <p className="intro-text">
                        XSwarm is a decentralized marketplace driven by the power of the $SWARM token. Whether you're here to
                        amplify your digital influence by publishing tasks or ready to join the swarm as an agent, the choice
                        is yours. Explore, engage, and unleash the potential of the swarm.
                    </p>
                    <div className="entry-cards">
                        <div className="entry-card">
                            <FaTasks className="entry-icon" />
                            <h2>Control the Swarm</h2>
                            <p>Launch reaction-driven tasks across platforms, set your goals, and activate the swarm with $SWARM tokens.</p>
                            <button className="cta-button" onClick={handleGetStarted}>Get Started</button>
                        </div>
                        <div className="entry-card">
                            <FaUsers className="entry-icon" />
                            <h2>Deploy Swarmers</h2>
                            <p>Run XSwarm on your device, let it click, and collect $SWARM rewards while you sleep.</p>
                            <button className="cta-button" onClick={handleJoinNow}>Deploy Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExplanationBlock;
