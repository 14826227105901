// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/pages/Home/Home.css */

.home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #0f0f0f;
    color: #fff;
    overflow-x: hidden;
}

.explanations-section {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    padding: 40px 20px;
    justify-content: center;
    width: 100%;
}

@media (max-width: 768px) {
    .explanations-section {
        flex-direction: column;
        align-items: center;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.css"],"names":[],"mappings":"AAAA,4BAA4B;;AAE5B;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,kBAAkB;IAClB,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI;QACI,sBAAsB;QACtB,mBAAmB;IACvB;AACJ","sourcesContent":["/* src/pages/Home/Home.css */\r\n\r\n.home-page {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    background: #0f0f0f;\r\n    color: #fff;\r\n    overflow-x: hidden;\r\n}\r\n\r\n.explanations-section {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    gap: 40px;\r\n    padding: 40px 20px;\r\n    justify-content: center;\r\n    width: 100%;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n    .explanations-section {\r\n        flex-direction: column;\r\n        align-items: center;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
