// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buy-swarm-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    color: var(--text-color);
}

.page-title {
    font-size: 2.5rem;
    color: var(--primary-color);
    text-shadow: 0 0 10px var(--neon-glow);
    margin-bottom: 10px;
}

.page-description {
    font-size: 1.2rem;
    color: var(--text-color);
    margin-bottom: 30px;
}

.swap-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    max-width: 500px;
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/BuySwarmPage/BuySwarmPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,wBAAwB;AAC5B;;AAEA;IACI,iBAAiB;IACjB,2BAA2B;IAC3B,sCAAsC;IACtC,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,wBAAwB;IACxB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;IACT,gBAAgB;IAChB,WAAW;AACf","sourcesContent":[".buy-swarm-page {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    padding: 40px 20px;\r\n    color: var(--text-color);\r\n}\r\n\r\n.page-title {\r\n    font-size: 2.5rem;\r\n    color: var(--primary-color);\r\n    text-shadow: 0 0 10px var(--neon-glow);\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.page-description {\r\n    font-size: 1.2rem;\r\n    color: var(--text-color);\r\n    margin-bottom: 30px;\r\n}\r\n\r\n.swap-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    gap: 20px;\r\n    max-width: 500px;\r\n    width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
