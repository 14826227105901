// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/globals.css';
import App from './App';
import { Buffer } from 'buffer';
window.Buffer = window.Buffer || Buffer;

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
