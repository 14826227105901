import React from 'react';
import './Connect.css';
import { FaTwitter, FaTelegram, FaDiscord } from 'react-icons/fa';

const Connect: React.FC = () => {
    return (
        <div className="connect">
            <div className="social-icons">
                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                    <FaTwitter className="icon" />
                </a>
                <a href="https://telegram.org" target="_blank" rel="noopener noreferrer">
                    <FaTelegram className="icon" />
                </a>
                <a href="https://discord.com" target="_blank" rel="noopener noreferrer">
                    <FaDiscord className="icon" />
                </a>
            </div>
        </div>
    );
};

export default Connect;
