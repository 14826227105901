import React, { useEffect, useState } from 'react';
import { FaUsers, FaRobot, FaTasks, FaCheckCircle, FaCoins } from 'react-icons/fa';
import './Metrics.css';

const Metrics: React.FC = () => {
    const [metrics, setMetrics] = useState({
        agentsCreated: 0,
        agentsRunning: 0,
        jobsPublished: 0,
        jobsCompleted: 0,
        totalRewards: 0,
    });
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:42069';

    useEffect(() => {
        const fetchMetrics = async () => {
            try {
                const response = await fetch(`${backendUrl}/api/metrics`);
                const data = await response.json();
                setMetrics(data);
            } catch (error) {
                console.error('Error fetching metrics:', error);
            }
        };

        fetchMetrics();
    }, []);

    return (
        <div className="metrics">
            <h2>Network Metrics</h2>
            <div className="metrics-scroll">
                <div className="metric-item">
                    <FaRobot className="metric-icon" />
                    <span>Agents Running:</span> {metrics.agentsRunning.toLocaleString()}
                </div>
                <div className="metric-item">
                    <FaUsers className="metric-icon" />
                    <span>Agents Created:</span> {metrics.agentsCreated.toLocaleString()}
                </div>
                <div className="metric-item">
                    <FaTasks className="metric-icon" />
                    <span>Jobs Published:</span> {metrics.jobsPublished.toLocaleString()}
                </div>
                <div className="metric-item">
                    <FaCheckCircle className="metric-icon" />
                    <span>Jobs Completed:</span> {metrics.jobsCompleted.toLocaleString()}
                </div>
                <div className="metric-item">
                    <FaCoins className="metric-icon" />
                    <span>Total Rewards:</span> {metrics.totalRewards.toLocaleString()} $SWARM
                </div>
            </div>
        </div>
    );
};

export default Metrics;
