import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ConnectionProvider, WalletProvider, useWallet } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import {
    PhantomWalletAdapter,
    SolflareWalletAdapter,
    TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';

import Home from './pages/Home/Home';
import CreateJob from './pages/CreateJob/CreateJob';
import Dashboard from './pages/Dashboard/Dashboard';
import CommentBankPage from './pages/CommentBankPage/CommentBankPage';
import Customs from './pages/Customs/Customs';
import CommentBankDetailPage from './components/CommentBankDetailPage/CommentBankDetailPage';
import BuySwarmPage from './pages/BuySwarmPage/BuySwarmPage';
import Header from './components/Common/Header';
import VerticalNav from './components/Common/VerticalNav';

import './App.css';
require('@solana/wallet-adapter-react-ui/styles.css');

const App: React.FC = () => {
    const [selectedEndpoint, setSelectedEndpoint] = useState(
        'https://chaotic-crimson-star.solana-mainnet.quiknode.pro/a7cdf02f65bc61c5443446e5db4ea0fda4cd455e'
    );

    useEffect(() => {
        const storedEndpoint = localStorage.getItem('solana-endpoint');
        if (storedEndpoint) {
            setSelectedEndpoint(storedEndpoint);
        }
    }, []);

    const wallets = [
        new PhantomWalletAdapter(),
        new SolflareWalletAdapter(),
        new TorusWalletAdapter(),
    ];

    return (
        <ConnectionProvider endpoint={selectedEndpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    <Router>
                        <Header />
                        <WalletConsumer>
                            {(walletAddress) => (
                                <>
                                    <VerticalNav walletAddress={walletAddress} />
                                    <Routes>
                                        <Route path="/" element={<Home />} />
                                        <Route path="/create-job" element={<CreateJob />} />
                                        <Route path="/dashboard" element={<Dashboard />} />
                                        <Route path="/comment-bank" element={<CommentBankPage />} />
                                        <Route path="/bank/:bankId" element={<CommentBankDetailPage />} />
                                        <Route path="/buy-swarm" element={<BuySwarmPage />} />
                                        <Route path="/customs" element={<Customs />} />
                                    </Routes>
                                </>
                            )}
                        </WalletConsumer>
                    </Router>
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

const WalletConsumer: React.FC<{ children: (walletAddress: string | null) => React.ReactNode }> = ({ children }) => {
    const { publicKey } = useWallet();
    const walletAddress = publicKey?.toBase58() || null;
    return <>{children(walletAddress)}</>;
};

export default App;
