import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaRegCopy } from 'react-icons/fa';
import './Banner.css';

const Banner: React.FC = () => {
    const phrases = ["Release the Swarm", "Control the Network", "Own the Web", "Join the Movement"];
    const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
    const [copyStatus, setCopyStatus] = useState("Copy");

    const handleCopy = () => {
        navigator.clipboard.writeText("6NPE8BZbY71L3EArLLjF2KLtJL7sJmYfysyMtHfEJkpW");
        setCopyStatus("Copied!");
        setTimeout(() => setCopyStatus("Copy"), 2000);
    };

    // Rotate phrases every 5 seconds without resetting animation
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    // Generate persistent swarm of circles
    const generateCircles = () => {
        return Array.from({ length: 25 }, (_, i) => (
            <motion.div
                key={i}
                className="circle"
                initial={{
                    x: Math.random() * 800 - 400,
                    y: Math.random() * 400 - 200,
                    opacity: 0.4,
                }}
                animate={{
                    x: [null, Math.random() * 800 - 400],
                    y: [null, Math.random() * 400 - 200],
                    scale: [0.7, 1, 0.7],
                }}
                transition={{
                    duration: Math.random() * 6 + 4,
                    repeat: Infinity,
                    repeatType: "mirror",
                    ease: "easeInOut",
                }}
            />
        ));
    };

    return (
        <div className="banner">
            <div className="murmuration-bg">
                {generateCircles()}
            </div>
            <div className="rolling-text">{phrases[currentPhraseIndex]}</div>
            <div className="ca">
                CA: 6NPE8BZbY71L3EArLLjF2KLtJL7sJmYfysyMtHfEJkpW
                <div className="copy-container" onClick={handleCopy}>
                    <span className="copy-tooltip">{copyStatus}</span>
                    <FaRegCopy className="copy-icon" />
                </div>
            </div>
        </div>
    );
};

export default Banner;
