import React, { useEffect, useState } from 'react';
import BankItem from '../BankItem/BankItem';
import './BankList.css';

interface Bank {
    id: string;
    name: string;
    creatorAddress: string;
    isPublic: boolean;
    imageUrl: string;
    commentCount: number;
}

interface BankListProps {
    searchTerm: string;
    connectedWalletAddress: string;
    isGodMode: boolean;
}

const BankList: React.FC<BankListProps> = ({ searchTerm, connectedWalletAddress, isGodMode }) => {
    const [banks, setBanks] = useState<Bank[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchBanks = async () => {
            setLoading(true);
            try {
                const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:42069';

                // Fetch categories with optional wallet address
                const response = await fetch(
                    `${backendUrl}/api/categories?walletAddress=${encodeURIComponent(
                        connectedWalletAddress || ''
                    )}`
                );
                const data = await response.json();

                // Map response data to the Bank format
                const formattedBanks = data.map((category: any) => ({
                    id: category.categoryId,
                    name: category.name,
                    creatorAddress: category.creationWallet,
                    isPublic: category.type === 'public',
                    imageUrl: category.projectThumbnail
                        ? `${ backendUrl }${category.projectThumbnail}`
                        : '/path/to/default/image.jpg',
                    commentCount: category.commentCount || 0,
                }));

                setBanks(formattedBanks);
            } catch (error) {
                console.error('Failed to fetch banks:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBanks();
    }, [connectedWalletAddress]);

    const filteredBanks = banks
        .filter((bank) => {
            // Always include public banks
            if (bank.isPublic) return true;

            // Include private banks created by the connected wallet
            if (bank.creatorAddress === connectedWalletAddress) return true;

            // Include all banks for god mode wallets
            if (isGodMode) return true;

            // Otherwise, exclude the bank
            return false;
        })
        .filter((bank) =>
            // Apply search filter
            bank.id.includes(searchTerm) || bank.name.toLowerCase().includes(searchTerm.toLowerCase())
        );

    return (
        <div className="bank-list">
            {loading ? (
                <p>Loading banks...</p>
            ) : filteredBanks.length > 0 ? (
                filteredBanks.map((bank) => <BankItem key={bank.id} bank={bank} />)
            ) : (
                <p>No banks found</p>
            )}
        </div>
    );
};

export default BankList;
