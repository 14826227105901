import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useWallet } from '@solana/wallet-adapter-react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import WalletInfo from './WalletInfo';
import './Header.css';

const Header: React.FC = () => {
    const { connected, connect, disconnect, publicKey, wallet } = useWallet();
    const { setVisible } = useWalletModal();
    const [showWalletInfo, setShowWalletInfo] = useState(false); // Toggle for wallet info visibility
    const [isXSwarmRunning, setIsXSwarmRunning] = useState(false); // Indicator for XSwarm status

    const truncatedAddress = publicKey
        ? `${publicKey.toBase58().slice(0, 4)}...${publicKey.toBase58().slice(-4)}`
        : '';

    const handleWalletAction = async () => {
        if (connected) {
            await disconnect();
        } else {
            if (!wallet) {
                setVisible(true);
            } else {
                await connect();
            }
        }
    };

    const toggleWalletInfo = () => {
        if (connected) {
            setShowWalletInfo((prev) => !prev); // Toggle wallet info visibility
        }
    };

    useEffect(() => {
        const checkXSwarmStatus = async () => {
            try {
                // Use your local IP address explicitly
                const localApiUrl = `http://localhost:4269/status`; // Replace with your local IP

                const response = await fetch(localApiUrl);
                const data = await response.json();
                setIsXSwarmRunning(data.status === 'running');
            } catch (error) {
                console.error('Failed to fetch XSwarm status:', error);
                setIsXSwarmRunning(false); // Mark as not running if unreachable
            }
        };

        checkXSwarmStatus();
        const interval = setInterval(checkXSwarmStatus, 5000); // Check status every 5 seconds

        return () => clearInterval(interval);
    }, []);



    return (
        <header className="header">
            <div className="logo">
                <Link to="/">XSwarm</Link>
            </div>
            {isXSwarmRunning && (
                <div className="xswarm-status">
                    <span className="running-indicator">XSwarm is running</span>
                </div>
            )}
            <div className="wallet-connect">
                <button
                    onClick={connected ? toggleWalletInfo : handleWalletAction}
                    className={`wallet-button ${connected ? 'connected' : ''}`}
                >
                    {connected ? truncatedAddress : 'Connect Wallet'}
                </button>
                {connected && showWalletInfo && (
                    <WalletInfo publicKey={publicKey?.toBase58() || ''} />
                )}
            </div>
        </header>
    );
};

export default Header;
