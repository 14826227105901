import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Comment from '../../components/Comment/Comment';
import AddCommentModal from '../../components/AddCommentModal/AddCommentModal';
import { FaPlusCircle } from 'react-icons/fa';
import './CommentBankDetailPage.css';

interface CommentData {
    _id: string;
    text: string;
    media_location?: string;
    creatorAddress: string;
    createdAt: string; // Assuming comments have a createdAt timestamp
}

const CommentBankDetailPage: React.FC = () => {
    const { bankId } = useParams<{ bankId: string }>();
    const [comments, setComments] = useState<CommentData[]>([]);
    const [bankName, setBankName] = useState<string>('');
    const [creationWallet, setCreationWallet] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:42069';

    const fetchComments = async () => {
        try {
            setLoading(true);
            setError(null);

            // Fetch bank details
            const bankResponse = await fetch(`${backendUrl}/api/categories/${bankId}`);
            if (!bankResponse.ok) throw new Error(`Failed to fetch bank: ${bankResponse.statusText}`);
            const bankData = await bankResponse.json();
            setBankName(bankData.name);
            setCreationWallet(bankData.creationWallet);

            // Fetch comments
            const commentsResponse = await fetch(`${backendUrl}/api/comments/category/${bankId}`);
            if (!commentsResponse.ok) throw new Error(`Failed to fetch comments: ${commentsResponse.statusText}`);
            const commentsData = await commentsResponse.json();

            // Sort comments by createdAt (newest first)
            const sortedComments = commentsData.sort(
                (a: CommentData, b: CommentData) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );

            setComments(sortedComments);
        } catch (err) {
            console.error('Error fetching bank details or comments:', err);
            setError('Failed to load bank details or comments. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (bankId) fetchComments();

        // Set up interval to refresh comments every 1 minute
        const interval = setInterval(() => {
            if (bankId) fetchComments();
        }, 60000); // 1 minute

        return () => clearInterval(interval); // Cleanup on component unmount
    }, [bankId]);

    const handleNewComment = (newComment: CommentData) => {
        setComments((prev) => [newComment, ...prev]);
    };

    const handleUpdateComment = () => {
        fetchComments(); // Refresh the list to reflect changes
    };

    const handleDeleteComment = () => {
        fetchComments(); // Refresh the list to remove the deleted comment
    };

    if (error) {
        return (
            <div className="comment-bank-detail-page">
                <p className="error-message">{error}</p>
            </div>
        );
    }

    return (
        <div className="comment-bank-detail-page">
            <header className="bank-header">
                <h2>{bankName}</h2>
                <p>Bank ID: {bankId}</p>
                <button
                    className="add-comment-button"
                    onClick={() => setIsModalOpen(true)}
                >
                    <FaPlusCircle className="icon" /> Add Comment
                </button>
            </header>
            <div className="comments-list">
                {loading ? (
                    <p>Loading comments...</p>
                ) : (
                    comments.map((comment) => (
                        <Comment
                            key={comment._id}
                            comment={comment}
                            creationWallet={creationWallet}
                            onUpdate={handleUpdateComment}
                            onDelete={handleDeleteComment}
                        />
                    ))
                )}
            </div>
            {isModalOpen && (
                <AddCommentModal
                    bankId={bankId!}
                    onClose={() => setIsModalOpen(false)}
                    onCommentAdded={handleNewComment}
                />
            )}
        </div>
    );
};

export default CommentBankDetailPage;
