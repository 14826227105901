// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.solana-button {
    padding: 12px 24px;
    border-radius: 8px;
    background-color: #4caf50;
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

    .solana-button:hover {
        background-color: #388e3c;
        transform: translateY(-2px);
    }

    .solana-button:disabled {
        background-color: #666;
        color: #ccc;
        cursor: not-allowed;
        box-shadow: none;
    }

    .solana-button.loading {
        cursor: progress;
    }

    .solana-button.error {
        background-color: #f44336;
        color: #fff;
    }

    .solana-button.success {
        background-color: #4caf50;
        color: #fff;
    }

.solana-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Common/SolanaButton.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,2DAA2D;IAC3D,yCAAyC;AAC7C;;IAEI;QACI,yBAAyB;QACzB,2BAA2B;IAC/B;;IAEA;QACI,sBAAsB;QACtB,WAAW;QACX,mBAAmB;QACnB,gBAAgB;IACpB;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,yBAAyB;QACzB,WAAW;IACf;;IAEA;QACI,yBAAyB;QACzB,WAAW;IACf;;AAEJ;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;AAClB","sourcesContent":[".solana-button {\r\n    padding: 12px 24px;\r\n    border-radius: 8px;\r\n    background-color: #4caf50;\r\n    color: #fff;\r\n    font-weight: bold;\r\n    font-size: 1rem;\r\n    text-align: center;\r\n    cursor: pointer;\r\n    border: none;\r\n    transition: background-color 0.3s ease, transform 0.2s ease;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);\r\n}\r\n\r\n    .solana-button:hover {\r\n        background-color: #388e3c;\r\n        transform: translateY(-2px);\r\n    }\r\n\r\n    .solana-button:disabled {\r\n        background-color: #666;\r\n        color: #ccc;\r\n        cursor: not-allowed;\r\n        box-shadow: none;\r\n    }\r\n\r\n    .solana-button.loading {\r\n        cursor: progress;\r\n    }\r\n\r\n    .solana-button.error {\r\n        background-color: #f44336;\r\n        color: #fff;\r\n    }\r\n\r\n    .solana-button.success {\r\n        background-color: #4caf50;\r\n        color: #fff;\r\n    }\r\n\r\n.solana-button-container {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    margin: 20px 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
