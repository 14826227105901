import React, { useEffect, useState } from 'react';
import { Connection, PublicKey } from '@solana/web3.js';
import { useWallet } from '@solana/wallet-adapter-react'; // Import the wallet hook
import { FaSignOutAlt } from 'react-icons/fa';
import './WalletInfo.css';

interface WalletInfoProps {
    publicKey: string;
    onBalancesUpdate?: (balances: { [key: string]: number }) => void; // Optional callback for balance updates
}

interface TokenBalance {
    symbol: string;
    balance: number;
}

const WalletInfo: React.FC<WalletInfoProps> = ({ publicKey, onBalancesUpdate }) => {
    const [balances, setBalances] = useState<TokenBalance[]>([]);
    const [error, setError] = useState<string | null>(null);

    const { disconnect } = useWallet(); // Get the disconnect method from the wallet adapter

    const connectionUrls = [
        'https://chaotic-crimson-star.solana-mainnet.quiknode.pro/a7cdf02f65bc61c5443446e5db4ea0fda4cd455e',
        'https://api.mainnet-beta.solana.com',
    ];

    const fetchBalances = async () => {
        let success = false;
        for (const url of connectionUrls) {
            try {
                const connection = new Connection(url);
                const solBalance = await connection.getBalance(new PublicKey(publicKey));
                const solBalanceInSol = solBalance / 1e9;

                const tokenAccounts = await connection.getParsedTokenAccountsByOwner(
                    new PublicKey(publicKey),
                    { programId: new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA') }
                );

                const tokenBalances: TokenBalance[] = [
                    { symbol: 'SOL', balance: solBalanceInSol },
                ];

                for (const account of tokenAccounts.value) {
                    const tokenAmount = account.account.data.parsed.info.tokenAmount.uiAmount;
                    const mintAddress = account.account.data.parsed.info.mint;

                    let tokenSymbol = 'Unknown';
                    if (mintAddress === '6NPE8BZbY71L3EArLLjF2KLtJL7sJmYfysyMtHfEJkpW') {
                        tokenSymbol = 'SWARM';
                    }

                    if (tokenAmount > 0) {
                        tokenBalances.push({ symbol: tokenSymbol, balance: tokenAmount });
                    }
                }

                setBalances(tokenBalances);
                onBalancesUpdate?.(Object.fromEntries(tokenBalances.map((t) => [t.symbol, t.balance])));

                success = true;
                break; // Exit loop on success
            } catch (error) {
                console.error(`Failed to fetch balances from ${url}:`, error);
            }
        }
        if (!success) setError('Failed to fetch wallet balances.');
    };


    useEffect(() => {
        fetchBalances();
    }, [publicKey]);

    const handleDisconnect = async () => {
        try {
            await disconnect();
            setBalances([]);
            setError(null);
            console.log('Wallet disconnected successfully');
        } catch (error) {
            console.error('Error disconnecting wallet:', error);
        }
    };


    return (
        <div className="wallet-info">
            <h4>Wallet Balances</h4>
            {error ? (
                <p className="error-message">{error}</p>
            ) : (
                <ul>
                    {balances.map((token) => (
                        <li key={token.symbol}>
                            {token.symbol}: {token.balance.toFixed(2)}
                        </li>
                    ))}
                </ul>
            )}
            <button className="disconnect-button" onClick={handleDisconnect}>
                <FaSignOutAlt className="disconnect-icon" /> Disconnect
            </button>
        </div>
    );
};

export default WalletInfo;
