import React, { useState } from 'react';
import { Connection, PublicKey, Transaction } from '@solana/web3.js';
import {
    TOKEN_PROGRAM_ID,
    createTransferInstruction,
    createBurnInstruction,
    getAssociatedTokenAddress,
    createAssociatedTokenAccountInstruction,
} from '@solana/spl-token';
import { useWallet } from '@solana/wallet-adapter-react';
import './SolanaButton.css';

interface SolanaButtonProps {
    type: 'send' | 'burn';
    amount: number;
    tokenMintAddress: string;
    recipientAddress?: string;
    onTransactionComplete?: (signature: string) => void;
    onError?: (error: Error) => void;
}

const SolanaButton: React.FC<SolanaButtonProps> = ({
    type,
    amount,
    tokenMintAddress,
    recipientAddress,
    onTransactionComplete,
    onError,
}) => {
    const [status, setStatus] = useState('Start Transaction');
    const [disabled, setDisabled] = useState(false);
    const { publicKey, sendTransaction, connected } = useWallet();

    // Primary and fallback connection endpoints
    const primaryConnection = new Connection(
        'https://chaotic-crimson-star.solana-mainnet.quiknode.pro/a7cdf02f65bc61c5443446e5db4ea0fda4cd455e'
    );
    const fallbackConnection = new Connection('https://api.mainnet-beta.solana.com');

    const getTokenDecimals = async (mintAddress: PublicKey) => {
        try {
            const mintInfo = await primaryConnection.getParsedAccountInfo(mintAddress);
            if (!mintInfo.value) throw new Error('Mint account not found');

            return (mintInfo.value.data as any).parsed.info.decimals || 9;
        } catch (primaryError) {
            console.warn('Primary connection failed, trying fallback:', primaryError);
            try {
                const mintInfo = await fallbackConnection.getParsedAccountInfo(mintAddress);
                if (!mintInfo.value) throw new Error('Mint account not found on fallback');

                return (mintInfo.value.data as any).parsed.info.decimals || 9;
            } catch (fallbackError) {
                console.error('Failed to retrieve token decimals on fallback:', fallbackError);
                throw new Error('Unable to retrieve token decimals');
            }
        }
    };

    const getOrCreateAssociatedTokenAccount = async (
        connection: Connection,
        payer: PublicKey,
        owner: PublicKey,
        mintAddress: PublicKey,
        transaction: Transaction
    ) => {
        const associatedTokenAddress = await getAssociatedTokenAddress(mintAddress, owner);

        const accountInfo = await connection.getAccountInfo(associatedTokenAddress);
        if (!accountInfo) {
            transaction.add(
                createAssociatedTokenAccountInstruction(
                    payer, // Fee payer
                    associatedTokenAddress,
                    owner,
                    mintAddress
                )
            );
        }

        return associatedTokenAddress;
    };

    const handleTransaction = async () => {
        if (!publicKey || !connected) {
            setStatus('Wallet not connected');
            return;
        }

        if (type === 'send' && !recipientAddress) {
            setStatus('Recipient address missing');
            return;
        }

        setDisabled(true);
        setStatus('Preparing transaction...');

        try {
            const mintAddress = new PublicKey(tokenMintAddress);
            const decimals = await getTokenDecimals(mintAddress);
            const tokenAmount = amount * Math.pow(10, decimals);

            const transaction = new Transaction();

            // Get or create the sender's associated token account
            const senderTokenAddress = await getOrCreateAssociatedTokenAccount(
                primaryConnection,
                publicKey,
                publicKey,
                mintAddress,
                transaction
            );

            if (type === 'send' && recipientAddress) {
                // Get or create the recipient's associated token account
                const recipientTokenAddress = await getOrCreateAssociatedTokenAccount(
                    primaryConnection,
                    publicKey,
                    new PublicKey(recipientAddress),
                    mintAddress,
                    transaction
                );

                transaction.add(
                    createTransferInstruction(
                        senderTokenAddress,
                        recipientTokenAddress,
                        publicKey,
                        tokenAmount,
                        [],
                        TOKEN_PROGRAM_ID
                    )
                );
            } else if (type === 'burn') {
                transaction.add(
                    createBurnInstruction(
                        senderTokenAddress,
                        mintAddress,
                        publicKey,
                        tokenAmount,
                        [],
                        TOKEN_PROGRAM_ID
                    )
                );
            } else {
                throw new Error('Invalid transaction type');
            }

            transaction.feePayer = publicKey;
            const { blockhash } = await primaryConnection.getLatestBlockhash();
            transaction.recentBlockhash = blockhash;

            setStatus('Requesting approval...');
            const signature = await sendTransaction(transaction, primaryConnection);

            setStatus('Confirming transaction...');
            await primaryConnection.confirmTransaction(signature, 'processed');

            setStatus('Transaction confirmed!');
            onTransactionComplete?.(signature);
        } catch (error: unknown) {
            const err = error instanceof Error ? error : new Error('An unknown error occurred');
            console.error('Transaction failed:', err);
            setStatus('Transaction failed');
            onError?.(err);
        } finally {
            setDisabled(false);
        }
    };

    return (
        <div className="solana-button-container">
            <button
                className={`solana-button ${status.includes('failed') ? 'error' : status.includes('confirmed') ? 'success' : ''
                    }`}
                onClick={handleTransaction}
                disabled={disabled}
            >
                {status}
            </button>
        </div>
    );
};

export default SolanaButton;
