import React, { useState, useEffect } from 'react';
import {
    FaHeart,
    FaRetweet,
    FaComment,
    FaBookmark,
    FaUserPlus,
    FaEye,
    FaThumbsUp,
    FaEdit,
    FaTimes,
} from 'react-icons/fa';
import { useWallet } from '@solana/wallet-adapter-react';
import SolanaButton from '../../components/Common/SolanaButton';
import './SetupJobModal.css';

interface SetupJobModalProps {
    targetUrl: string;
    platform: string;
    onClose: () => void;
}

const serviceReactions = {
    X: {
        mainLabel: 'Likes',
        mainIcon: <FaHeart />,
        additionalReactions: [
            { label: 'Retweets', icon: <FaRetweet /> },
            { label: 'Comments', icon: <FaComment /> },
            { label: 'Bookmarks', icon: <FaBookmark /> },
            { label: 'Follows', icon: <FaUserPlus /> },
        ],
    },
    YouTube: {
        mainLabel: 'Streams',
        mainIcon: <FaEye />,
        additionalReactions: [
            { label: 'Likes', icon: <FaThumbsUp /> },
            { label: 'Comments', icon: <FaComment /> },
        ],
    },
    SoundCloud: {
        mainLabel: 'Plays',
        mainIcon: <FaEye />,
        additionalReactions: [
            { label: 'Likes', icon: <FaThumbsUp /> },
            { label: 'Comments', icon: <FaComment /> },
            { label: 'Reposts', icon: <FaRetweet /> },
        ],
    },
};

const SetupJobModal: React.FC<SetupJobModalProps> = ({ targetUrl, platform, onClose }) => {
    const { connected, connect, publicKey } = useWallet();
    const [url, setUrl] = useState(targetUrl);
    const [mainReactionAmount, setMainReactionAmount] = useState<number | ''>('');
    const [additionalReactions, setAdditionalReactions] = useState<{ [key: string]: number }>({});
    const [comments, setComments] = useState<{ bank: string; percentage: number }[]>([]);
    const [categories, setCategories] = useState<any[]>([]);
    const [pricing, setPricing] = useState<{ [key: string]: number }>({});
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [error, setError] = useState<string | null>(null);

    const currentServiceReactions = serviceReactions[platform];
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:42069';

    useEffect(() => {
        const fetchPricing = async () => {
            try {
                const response = await fetch(`${backendUrl}/api/targets/pricing`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ platform }),
                });
                if (!response.ok) throw new Error('Failed to fetch pricing data');
                const data = await response.json();
                setPricing(data);
            } catch (error) {
                console.error('Error fetching pricing:', error);
            }
        };

        fetchPricing();
    }, [platform]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const walletAddress = publicKey?.toBase58();
                const response = await fetch(`${backendUrl}/api/categories?walletAddress=${walletAddress || ''}`);
                if (!response.ok) throw new Error('Failed to fetch categories');
                const data = await response.json();
                setCategories(data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, [platform, publicKey]);

    const calculatePrice = () => {
        const mainPrice = (mainReactionAmount || 0) * (pricing[currentServiceReactions.mainLabel.toLowerCase()] || 0);
        const additionalPrice = Object.entries(additionalReactions).reduce((sum, [key, amount]) => {
            return sum + amount * (pricing[key.toLowerCase()] || 0);
        }, 0);
        setTotalPrice(mainPrice + additionalPrice);
    };

    useEffect(() => {
        calculatePrice();
    }, [mainReactionAmount, additionalReactions, pricing]);

    const handleAdditionalReactionChange = (label: string, amount: number) => {
        setAdditionalReactions((prev) => ({ ...prev, [label]: Math.min(amount, mainReactionAmount || 0) }));
    };

    const addCommentBank = () => {
        const newComments = [...comments];
        if (newComments.length === 0) {
            newComments.push({ bank: '', percentage: 100 }); // Default first bank to 100%
        } else {
            newComments.push({ bank: '', percentage: 0 });
        }
        setComments(newComments);
    };

    const updateCommentBank = (index: number, field: string, value: string | number) => {
        const updatedComments = [...comments];
        updatedComments[index] = { ...updatedComments[index], [field]: value };
        setComments(updatedComments);
    };

    const validatePercentages = () => {
        const totalPercentage = comments.reduce((sum, { percentage }) => sum + percentage, 0);
        return totalPercentage === 100;
    };

    const validateTargetData = (): boolean => {
        if (!url) {
            setError('Target URL is required.');
            return false;
        }
        if (!platform) {
            setError('Platform is required.');
            return false;
        }
        if (!mainReactionAmount || mainReactionAmount <= 0) {
            setError('Main reaction amount must be greater than zero.');
            return false;
        }
        if (!validatePercentages()) {
            setError('Comment percentages must add up to 100%.');
            return false;
        }
        setError(null);
        return true;
    };

    const prepareCommentDistribution = () => {
        return comments.map(({ bank, percentage }) => ({
            bank,
            percentage,
        }));
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-modal-button" onClick={onClose}>
                    <FaTimes />
                </button>
                <h3>Set Up Job for {platform}</h3>
                {error && <p className="error">{error}</p>}
                <div className="editable-url">
                    <span>Target URL:</span>
                    <div className="url-input">
                        <input
                            type="text"
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                            placeholder="Enter or edit the target URL"
                        />
                        <FaEdit className="edit-icon" />
                    </div>
                </div>

                <div className="main-reaction">
                    <span>
                        {currentServiceReactions.mainIcon}
                        {currentServiceReactions.mainLabel}
                    </span>
                    <input
                        type="number"
                        value={mainReactionAmount || ''}
                        onChange={(e) => setMainReactionAmount(Number(e.target.value) || '')}
                        placeholder="0"
                    />
                </div>

                <div className="additional-reactions">
                    <h4>Additional Reactions</h4>
                    {currentServiceReactions.additionalReactions.map((reaction) => (
                        <div key={reaction.label} className="reaction-input">
                            <span>
                                {reaction.icon} {reaction.label}
                            </span>
                            <input
                                type="number"
                                value={additionalReactions[reaction.label] || ''}
                                onChange={(e) =>
                                    handleAdditionalReactionChange(reaction.label, Number(e.target.value) || 0)
                                }
                                placeholder="0"
                            />
                        </div>
                    ))}
                </div>

                {additionalReactions['Comments'] > 0 && (
                    <div className="comment-setup">
                        <h4>Comments</h4>
                        {comments.map((comment, index) => (
                            <div key={index} className="comment-row">
                                <select
                                    value={comment.bank}
                                    onChange={(e) => updateCommentBank(index, 'bank', e.target.value)}
                                >
                                    <option value="">Select Bank</option>
                                    {categories.map((category) => (
                                        <option key={category.categoryId} value={category.categoryId}>
                                            {category.name}
                                        </option>
                                    ))}
                                </select>
                                <input
                                    type="number"
                                    value={comment.percentage}
                                    onChange={(e) =>
                                        updateCommentBank(index, 'percentage', Math.max(0, Number(e.target.value)))
                                    }
                                    placeholder="0"
                                />
                                <span>%</span>
                            </div>
                        ))}
                        <button className="add-comment-button" onClick={addCommentBank}>
                            Add Comment
                        </button>
                        {!validatePercentages() && <p className="error">Percentages must add up to 100%</p>}
                    </div>
                )}

                <div className="price-display">
                    Total Price: <strong>{totalPrice.toFixed(2)} $SWARM</strong>
                </div>

                {connected && publicKey ? (
                    <SolanaButton
                        type="send"
                        amount={totalPrice}
                        tokenMintAddress="6NPE8BZbY71L3EArLLjF2KLtJL7sJmYfysyMtHfEJkpW"
                        recipientAddress="F5Jxtobkhwu2SSgs9o4Yx1DchPGHADay9GGx1m37XLm4"
                        onTransactionComplete={async (signature) => {
                            // Prepare target data
                            const targetData = {
                                target_url: url,
                                platform,
                                actions: {
                                    likes: mainReactionAmount || 0,
                                    ...additionalReactions,
                                },
                                commentDistribution: prepareCommentDistribution(),
                                rewardTokenTicker: '$swarm',
                                rewardTokenAddress: '6NPE8BZbY71L3EArLLjF2KLtJL7sJmYfysyMtHfEJkpW',
                                project_id: '', // Replace with your project ID logic
                                signature, // Pass the signature from the Solana transaction
                                fundingWallet: publicKey?.toBase58(),
                            };

                            try {
                                // Send the target data to the new /targets endpoint
                                const response = await fetch(`${backendUrl}/api/targets`, {
                                    method: 'POST',
                                    headers: { 'Content-Type': 'application/json' },
                                    body: JSON.stringify(targetData),
                                });

                                if (response.ok) {
                                    alert('Target published successfully!');
                                } else {
                                    const error = await response.json();
                                    alert(`Failed to publish target: ${error.error}`);
                                }
                            } catch (error) {
                                console.error('Error publishing target:', error);
                                alert('An unexpected error occurred while publishing the target.');
                            }
                        }}
                    />


                ) : (
                    <button className="connect-wallet-button" onClick={() => connect()}>
                        Connect Wallet
                    </button>
                )}
            </div>
        </div>
    );
};

export default SetupJobModal;
