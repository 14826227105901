import React from 'react';
import { FaTwitter, FaYoutube, FaSoundcloud } from 'react-icons/fa';
import './Services.css';

const Services: React.FC = () => {
    return (
        <div className="services">
            <div className="click-swarm-animation"></div> {/* Swarm click animation */}
            <div className="services-content">
                <div className="services-icons">
                    <div className="service-item">
                        <FaTwitter className="service-icon" />
                        <span className="service-label">X</span>
                    </div>
                    <div className="service-item">
                        <FaYoutube className="service-icon" />
                        <span className="service-label">YouTube</span>
                    </div>
                    <div className="service-item">
                        <FaSoundcloud className="service-icon" />
                        <span className="service-label">SoundCloud</span>
                    </div>
                </div>
                <div className="services-text">
                    <h2>Compatible Platforms</h2>
                    <p>
                        XSwarm supports a variety of platforms, allowing you to publish tasks, interact, and gather
                        engagement across multiple networks. Empower your reach on social media and music sharing platforms with ease.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Services;
