import React, { useState } from 'react';
import { FaGlobe } from 'react-icons/fa';
import { useWallet } from '@solana/wallet-adapter-react';
import SetupJobModal from '../../components/SetupJobModal/SetupJobModal';
import './CreateJob.css';

const CreateJob: React.FC = () => {
    const [targetUrl, setTargetUrl] = useState<string>('');
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [platform, setPlatform] = useState<string | null>(null);
    const { publicKey } = useWallet();

    const detectPlatform = (url: string): string | null => {
        if (url.includes('twitter.com') || url.includes('x.com')) return 'X';
        if (url.includes('youtube.com') || url.includes('youtu.be')) return 'YouTube';
        if (url.includes('soundcloud.com')) return 'SoundCloud';
        return null;
    };

    const handleSetupJob = () => {
        const detectedPlatform = detectPlatform(targetUrl);
        if (!detectedPlatform) {
            alert('Could not detect a valid platform from the URL. Please enter a valid target URL.');
            return;
        }
        setPlatform(detectedPlatform);
        setIsModalOpen(true);
    };

    return (
        <div className="create-job-page">
            <h2 className="page-title">Create a New Job</h2>
            <p className="page-description">
                Enter the target URL to begin setting up your job.
            </p>
            <div className="target-url-input">
                <FaGlobe className="icon" />
                <input
                    type="text"
                    placeholder="Enter the target URL..."
                    value={targetUrl}
                    onChange={(e) => setTargetUrl(e.target.value)}
                />
            </div>
            <button
                className="setup-job-button"
                onClick={handleSetupJob}
                disabled={!targetUrl.trim()}
            >
                Set Up Job
            </button>
            {isModalOpen && platform && (
                <SetupJobModal
                    targetUrl={targetUrl}
                    platform={platform}
                    onClose={() => setIsModalOpen(false)}
                />
            )}
        </div>
    );
};

export default CreateJob;
