import React from 'react';
import './SwapCard.css';

interface SwapCardProps {
    assetType: string;
    assetName: string;
    balance: string;
    onInputChange: (amount: string) => void;
    disabled?: boolean; // Disable the input field if true
    outputValue?: string; // For the output field (display-only)
}

const SwapCard: React.FC<SwapCardProps> = ({ assetType, assetName, balance, onInputChange, disabled = false, outputValue }) => {
    return (
        <div className="swap-card">
            <div className="card-header">
                <span>{assetType}</span>
                <span className="balance">Balance: {balance} {assetName}</span>
            </div>
            <div className="input-section">
                <input
                    type="text"
                    placeholder="0.0"
                    className="input-amount"
                    value={disabled ? outputValue || '' : undefined} // Use outputValue for disabled fields
                    onChange={(e) => !disabled && onInputChange(e.target.value)} // Prevent changes for disabled fields
                    disabled={disabled} // Disable the input field for outputValue
                />
                <span className="asset-name">{assetName}</span>
            </div>
        </div>
    );
};

export default SwapCard;
