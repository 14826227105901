import React, { useState } from 'react';
import { FaFileImage, FaLock, FaLockOpen, FaPlusCircle } from 'react-icons/fa';
import SolanaButton from '../Common/SolanaButton'; // Import SolanaButton
import { useWallet } from '@solana/wallet-adapter-react';
import './CreateBankModal.css';

interface CreateBankModalProps {
    onClose: () => void;
}

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:42069';

const CreateBankModal: React.FC<CreateBankModalProps> = ({ onClose }) => {
    const [name, setName] = useState('');
    const [isPublic, setIsPublic] = useState(true);
    const [file, setFile] = useState<File | null>(null);
    const [uploading, setUploading] = useState(false);
    const { publicKey } = useWallet();

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const selectedFile = e.target.files[0];
            if (selectedFile.size > MAX_FILE_SIZE) {
                alert('File size exceeds the 5MB limit. Please choose a smaller file.');
                return;
            }

            const allowedTypes = ['image/', 'video/'];
            if (!allowedTypes.some((type) => selectedFile.type.startsWith(type))) {
                alert('Invalid file type. Only images, GIFs, and videos are allowed.');
                return;
            }

            setFile(selectedFile);
        }
    };

    const handleCategoryCreation = async (signature: string) => {
        if (!name.trim()) {
            alert('Please provide a valid category name.');
            return;
        }

        if (!file) {
            alert('Please upload a media file.');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('media', file);

            const category = JSON.stringify({
                name: name.trim(),
                type: isPublic ? 'public' : 'private',
                projectToken: '6NPE8BZbY71L3EArLLjF2KLtJL7sJmYfysyMtHfEJkpW',
                projectWebsite: '',
                botStrategy: 'random',
                commentRequirements: 'burn',
                commentCommitment: '1000',
                reactionCost: '69',
                commentCost: '69',
                creationWallet: publicKey?.toBase58() || 'UnknownWallet',
                bookmarkReward: '10',
                commentReward: '100',
                likeReward: '40',
                retweetReward: '20',
                rewardPool: '',
                tokenDecimals: '9',
                projectTicker: '$swarm',
            });

            formData.append('category', category);
            formData.append('signature', signature);

            setUploading(true);

            const response = await fetch(`${backendUrl}/api/categories`, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            setUploading(false);

            if (response.ok) {
                alert(`Category created successfully! ID: ${data.category.categoryId}`);
                onClose(); // Close the modal on success
            } else {
                alert(`Error: ${data.error}`);
            }
        } catch (error) {
            setUploading(false);
            console.error('Error creating category:', error);
            alert('An error occurred while creating the category. Please try again.');
        }
    };

    const handleTransactionError = (error: Error) => {
        console.error('Transaction error:', error);
        alert('Transaction failed. Please check your wallet and try again.');
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Create New Category</h2>

                {/* Input for Category Name */}
                <div className="input-group">
                    <FaPlusCircle className="icon" />
                    <input
                        type="text"
                        placeholder="Category Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="input-field"
                    />
                </div>

                {/* File Upload */}
                <div className="input-group">
                    <label htmlFor="file-upload" className="file-upload-label">
                        <FaFileImage className="icon" />
                        <span>{uploading ? 'Uploading...' : 'Add Media (Image/GIF/Video)'}</span>
                    </label>
                    <input
                        type="file"
                        id="file-upload"
                        onChange={handleFileChange}
                        className="file-input"
                        aria-label="Upload Media"
                    />
                </div>

                {/* Privacy Toggle */}
                <div className="privacy-toggle">
                    <label className="privacy-label">
                        {isPublic ? <FaLockOpen className="icon" /> : <FaLock className="icon" />}
                        <span>{isPublic ? 'Public' : 'Private'}</span>
                        <input
                            type="checkbox"
                            checked={isPublic}
                            onChange={() => setIsPublic(!isPublic)}
                            className="checkbox-input"
                        />
                    </label>
                </div>

                {/* Modal Actions */}
                <div className="modal-actions">
                    <SolanaButton
                        type="burn" // Burn tokens to confirm category creation
                        amount={10} // Example amount of tokens to burn
                        tokenMintAddress="6NPE8BZbY71L3EArLLjF2KLtJL7sJmYfysyMtHfEJkpW"
                        onTransactionComplete={handleCategoryCreation} // Proceed with category creation on confirmation
                        onError={handleTransactionError} // Handle transaction errors
                    />
                    <button onClick={onClose} className="cancel-button">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateBankModal;
