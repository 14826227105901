import React, { useState, useEffect } from 'react';
import {
    FaHeart,
    FaRetweet,
    FaComment,
    FaBookmark,
    FaUserPlus,
    FaEye,
    FaThumbsUp,
    FaEdit,
    FaTimes,
    FaPlus,
    FaDatabase,
    FaRobot,
    FaPercentage,
    FaChevronUp,
    FaChevronDown,
    FaTrash,
} from 'react-icons/fa';
import { useWallet } from '@solana/wallet-adapter-react';
import SolanaButton from '../../components/Common/SolanaButton';
import './SetupJobModal.css';

interface SetupJobModalProps {
    targetUrl: string;
    platform: string;
    onClose: () => void;
}

interface CommentConfig {
    bank: string;         // Bank ID or prompt
    percentage: number;
    usePrompt: boolean;   // Whether this comment is using a custom prompt
    prompt: string;       // The custom prompt text
}

const serviceReactions = {
    X: {
        mainLabel: 'Likes',
        mainIcon: <FaHeart />,
        additionalReactions: [
            { label: 'Retweets', icon: <FaRetweet /> },
            { label: 'Comments', icon: <FaComment /> },
            { label: 'Bookmarks', icon: <FaBookmark /> },
            { label: 'Follows', icon: <FaUserPlus /> },
        ],
    },
    YouTube: {
        mainLabel: 'Streams',
        mainIcon: <FaEye />,
        additionalReactions: [
            { label: 'Likes', icon: <FaThumbsUp /> },
            { label: 'Comments', icon: <FaComment /> },
        ],
    },
    SoundCloud: {
        mainLabel: 'Plays',
        mainIcon: <FaEye />,
        additionalReactions: [
            { label: 'Likes', icon: <FaThumbsUp /> },
            { label: 'Comments', icon: <FaComment /> },
            { label: 'Reposts', icon: <FaRetweet /> },
        ],
    },
};

const SetupJobModal: React.FC<SetupJobModalProps> = ({ targetUrl, platform, onClose }) => {
    const { connected, connect, publicKey } = useWallet();
    const [url, setUrl] = useState(targetUrl);
    const [mainReactionAmount, setMainReactionAmount] = useState<number>(0);
    const [additionalReactions, setAdditionalReactions] = useState<{ [key: string]: number }>({});
    const [comments, setComments] = useState<CommentConfig[]>([]);
    const [categories, setCategories] = useState<any[]>([]);
    const [pricing, setPricing] = useState<{ [key: string]: number }>({});
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [error, setError] = useState<string | null>(null);

    const currentServiceReactions = serviceReactions[platform];
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:42069';

    useEffect(() => {
        const fetchPricing = async () => {
            try {
                const response = await fetch(`${backendUrl}/api/targets/pricing`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ platform }),
                });
                if (!response.ok) throw new Error('Failed to fetch pricing data');
                const data = await response.json();
                setPricing(data);
            } catch (error) {
                console.error('Error fetching pricing:', error);
            }
        };
        fetchPricing();
    }, [platform, backendUrl]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const walletAddress = publicKey?.toBase58();
                const response = await fetch(`${backendUrl}/api/categories?walletAddress=${walletAddress || ''}`);
                if (!response.ok) throw new Error('Failed to fetch categories');
                const data = await response.json();
                setCategories(data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        fetchCategories();
    }, [platform, publicKey, backendUrl]);

    // Calculate the total price any time reactions or pricing updates
    const calculatePrice = () => {
        const mainPrice =
            mainReactionAmount *
            (pricing[currentServiceReactions.mainLabel.toLowerCase()] || 0);

        const additionalPrice = Object.entries(additionalReactions).reduce((sum, [key, amount]) => {
            return sum + amount * (pricing[key.toLowerCase()] || 0);
        }, 0);

        setTotalPrice(mainPrice + additionalPrice);
    };

    useEffect(() => {
        calculatePrice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainReactionAmount, additionalReactions, pricing]);

    // Handle digit increment/decrement for main reaction
    const handleMainReactionChange = (value: number) => {
        if (value < 0) return;
        setMainReactionAmount(value);

        // Update additional reactions if they exceed the new main value
        const updatedAdditional = { ...additionalReactions };
        let hasChanges = false;

        Object.keys(updatedAdditional).forEach((key) => {
            if (updatedAdditional[key] > value) {
                updatedAdditional[key] = value;
                hasChanges = true;
            }
        });

        if (hasChanges) {
            setAdditionalReactions(updatedAdditional);
        }
    };

    // Handle digit input change for additional reactions
    const handleAdditionalReactionChange = (label: string, value: number) => {
        if (value < 0) return;
        if (value > mainReactionAmount) value = mainReactionAmount;

        setAdditionalReactions((prev) => ({
            ...prev,
            [label]: value,
        }));

        // Remove the automatic comment initialization
        // We'll let the user explicitly add comments via buttons
    };

    // Add new comment config - now with type parameter
    const addCommentConfig = (usePrompt: boolean) => {
        if (comments.length === 0) {
            setComments([{
                bank: '',
                percentage: 100,
                usePrompt,
                prompt: '',
            }]);
            return;
        }

        // Calculate how to redistribute percentages
        const numComments = comments.length;
        const newPercentagePerComment = Math.floor(100 / (numComments + 1));
        const remainder = 100 - (newPercentagePerComment * (numComments + 1));

        // Redistribute percentages
        const updatedComments = comments.map(comment => ({
            ...comment,
            percentage: newPercentagePerComment
        }));

        // Add the new comment with adjusted percentage
        updatedComments.push({
            bank: '',
            percentage: newPercentagePerComment + remainder,
            usePrompt,
            prompt: '',
        });

        setComments(updatedComments);
    };

    // Update a comment config
    const updateCommentConfig = (
        index: number,
        field: keyof CommentConfig,
        value: string | number | boolean
    ) => {
        const updated = [...comments];

        // Special handling for percentage changes to maintain 100% total
        if (field === 'percentage') {
            const numericValue = Number(value);
            const oldValue = updated[index].percentage;
            const difference = numericValue - oldValue;

            // If we're trying to increase beyond 100% total, don't allow it
            const totalOthers = comments.reduce((sum, comment, idx) =>
                idx !== index ? sum + comment.percentage : sum, 0);

            if (totalOthers + numericValue > 100) {
                return;
            }

            // If only one comment, force it to be 100%
            if (comments.length === 1) {
                updated[0] = {
                    ...updated[0],
                    percentage: 100
                };
                setComments(updated);
                return;
            }

            // Distribute the difference among other comments
            if (difference !== 0 && comments.length > 1) {
                const totalToAdjust = comments.length - 1;
                const adjustmentPerComment = Math.floor(Math.abs(difference) / totalToAdjust);
                let remainingAdjustment = Math.abs(difference) - (adjustmentPerComment * totalToAdjust);

                for (let i = 0; i < comments.length; i++) {
                    if (i !== index) {
                        let adjustment = difference > 0
                            ? -adjustmentPerComment
                            : adjustmentPerComment;

                        if (remainingAdjustment > 0) {
                            adjustment += difference > 0 ? -1 : 1;
                            remainingAdjustment--;
                        }

                        // Ensure we don't go below 0
                        const newPercentage = Math.max(0, updated[i].percentage + adjustment);
                        updated[i] = {
                            ...updated[i],
                            percentage: newPercentage
                        };
                    }
                }
            }
        }

        // Update the specific field
        updated[index] = {
            ...updated[index],
            [field]: value,
        };

        setComments(updated);
    };

    // Remove a comment config and redistribute percentages
    const removeCommentConfig = (index: number) => {
        if (comments.length <= 1) return;

        const percentageToRedistribute = comments[index].percentage;
        const remainingComments = comments.length - 1;
        const additionalPercentagePerComment = Math.floor(percentageToRedistribute / remainingComments);
        const remainder = percentageToRedistribute - (additionalPercentagePerComment * remainingComments);

        const updatedComments = comments
            .filter((_, i) => i !== index)
            .map((comment, i) => ({
                ...comment,
                percentage: comment.percentage + additionalPercentagePerComment + (i === 0 ? remainder : 0)
            }));

        setComments(updatedComments);
    };

    // Check if comment percentages add up to 100%
    const validatePercentages = (): boolean => {
        const totalPercentage = comments.reduce((sum, { percentage }) => sum + percentage, 0);
        return totalPercentage === 100;
    };

    // Validate form data
    const validateTargetData = (): boolean => {
        if (!url) {
            setError('Target URL is required.');
            return false;
        }
        if (!platform) {
            setError('Platform is required.');
            return false;
        }
        if (mainReactionAmount <= 0) {
            setError('Main reaction amount must be greater than zero.');
            return false;
        }
        if (additionalReactions['Comments'] && additionalReactions['Comments'] > 0 && !validatePercentages()) {
            setError('Comment percentages must add up to 100%.');
            return false;
        }
        setError(null);
        return true;
    };

    // Prepare comment data for submission
    const prepareCommentDistribution = () => {
        // If no comments are configured but comments are requested, add a default
        if (additionalReactions['Comments'] > 0 && comments.length === 0) {
            return [{
                bank: '', // Default to empty bank (will be handled by backend)
                percentage: 100,
            }];
        }

        return comments.map((comment) => {
            if (comment.usePrompt) {
                // If user typed a prompt, store that in place of "bank"
                return {
                    bank: comment.prompt,
                    percentage: comment.percentage,
                };
            }
            return {
                bank: comment.bank,
                percentage: comment.percentage,
            };
        });
    };

    // Handle publishing the job
    const handlePublish = async (signature: string) => {
        if (!validateTargetData()) return;

        // Prepare target data
        const targetData = {
            target_url: url,
            platform,
            actions: {
                [currentServiceReactions.mainLabel.toLowerCase()]: mainReactionAmount,
                ...additionalReactions,
            },
            commentDistribution: prepareCommentDistribution(),
            rewardTokenTicker: '$swarm',
            rewardTokenAddress: '6NPE8BZbY71L3EArLLjF2KLtJL7sJmYfysyMtHfEJkpW',
            project_id: '', // Replace with your project ID logic
            signature, // Pass the signature from the Solana transaction
            fundingWallet: publicKey?.toBase58(),
        };

        try {
            const response = await fetch(`${backendUrl}/api/targets`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(targetData),
            });

            if (response.ok) {
                alert('Target published successfully!');
                onClose();
            } else {
                const error = await response.json();
                alert(`Failed to publish target: ${error.error}`);
            }
        } catch (error) {
            console.error('Error publishing target:', error);
            alert('An unexpected error occurred while publishing the target.');
        }
    };

    // Digit selector component - updated to be more compact and visible

    // 2. Update the DigitSelector component to be more compact
    const DigitSelector = ({
        value,
        onChange,
        max = null,
        placeholder = "0",
        disabled = false
    }: {
        value: number,
        onChange: (val: number) => void,
        max?: number | null,
        placeholder?: string,
        disabled?: boolean
    }) => (
        <div className="digit-selector">
            <button
                className="digit-btn-minimal"
                onClick={() => onChange(Math.max(0, value - 1))}
                disabled={disabled || value <= 0}
                aria-label="Decrease value"
            >
                -
            </button>
            <input
                type="number"
                value={value || ''}
                onChange={(e) => onChange(parseInt(e.target.value) || 0)}
                placeholder={placeholder}
                disabled={disabled}
            />
            <button
                className="digit-btn-minimal"
                onClick={() => onChange(value + 1)}
                disabled={disabled || (max !== null && value >= max)}
                aria-label="Increase value"
            >
                +
            </button>
        </div>
    );

    return (
        <div className="modal-overlay">
            <div className="setup-modal">
                <button className="close-modal-button" onClick={onClose} aria-label="Close modal">
                    <FaTimes />
                </button>
                <h3>Set Up Job for {platform}</h3>

                {error && <div className="error-message">{error}</div>}

                {/* URL Section */}
                <div className="input-section">
                    <label>Target URL</label>
                    <div className="url-field">
                        <input
                            type="text"
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                            placeholder="Enter target URL"
                        />
                        <FaEdit />
                    </div>
                </div>

                {/* Main Goal Section - Updated layout */}
                <div className="input-section">
                    <label>Main Goal</label>
                    <div className="main-goal">
                        <div className="main-goal-wrapper">
                            <div className="reaction-icon">
                                {currentServiceReactions.mainIcon}
                            </div>
                            <span>{currentServiceReactions.mainLabel}</span>
                        </div>
                        <DigitSelector
                            value={mainReactionAmount}
                            onChange={handleMainReactionChange}
                        />
                    </div>
                </div>

                {/* Additional Engagement Section - Fixed grid layout */}
                <div className="input-section">
                    <label>Additional Engagement</label>
                    <div className="additional-reactions-grid">
                        {currentServiceReactions.additionalReactions.map((reaction) => (
                            <div key={reaction.label} className="reaction-item">
                                <div className="reaction-info">
                                    <div className="reaction-icon small">
                                        {reaction.icon}
                                    </div>
                                    <span>{reaction.label}</span>
                                </div>
                                <DigitSelector
                                    value={additionalReactions[reaction.label] || 0}
                                    onChange={(val) => handleAdditionalReactionChange(reaction.label, val)}
                                    max={mainReactionAmount}
                                    disabled={mainReactionAmount === 0}
                                />
                            </div>
                        ))}
                    </div>
                </div>

                {/* Comment Configuration Section - Updated with separate buttons */}
                {additionalReactions['Comments'] > 0 && (
                    <div className="input-section">
                        <div className="comment-header">
                            <label>Comment Configuration</label>
                            <div className="comment-actions centered">
                                <button className="add-config-btn bank" onClick={() => addCommentConfig(false)}>
                                    <FaDatabase /> Add Bank
                                </button>
                                <button className="add-config-btn prompt" onClick={() => addCommentConfig(true)}>
                                    <FaRobot /> Add Prompt
                                </button>
                            </div>
                        </div>


                        <div className="comment-configs">
                            {comments.length > 0 && (
                                <>
                                    {comments.map((comment, index) => (
                                        <div key={index} className="comment-config">
                                            <div className="comment-type-indicator">
                                                {comment.usePrompt ?
                                                    <div className="type-icon prompt">
                                                        <FaRobot />
                                                    </div> :
                                                    <div className="type-icon bank">
                                                        <FaDatabase />
                                                    </div>
                                                }
                                            </div>

                                            <div className="comment-content">
                                                {comment.usePrompt ? (
                                                    <input
                                                        type="text"
                                                        placeholder="Enter AI prompt"
                                                        value={comment.prompt}
                                                        onChange={(e) => updateCommentConfig(index, 'prompt', e.target.value)}
                                                        className="prompt-input"
                                                    />
                                                ) : (
                                                    <select
                                                        value={comment.bank}
                                                        onChange={(e) => updateCommentConfig(index, 'bank', e.target.value)}
                                                        className="bank-select"
                                                    >
                                                        <option value="">Select Comment Bank</option>
                                                        {categories.map((category) => (
                                                            <option
                                                                key={category.categoryId}
                                                                value={category.categoryId}
                                                            >
                                                                {category.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                )}
                                            </div>

                                            <div className="comment-percentage">
                                                <FaPercentage />
                                                <input
                                                    type="number"
                                                    value={comment.percentage}
                                                    onChange={(e) => updateCommentConfig(
                                                        index,
                                                        'percentage',
                                                        Math.max(0, Math.min(100, parseInt(e.target.value) || 0))
                                                    )}
                                                    min="0"
                                                    max="100"
                                                />
                                            </div>

                                            <button
                                                className="remove-config"
                                                onClick={() => removeCommentConfig(index)}
                                                disabled={comments.length <= 1}
                                                aria-label="Remove comment"
                                            >
                                                <FaTimes />
                                            </button>
                                        </div>
                                    ))}

                                    <div className="percentage-indicator">
                                        <div className="percentage-bar">
                                            <div
                                                className={`percentage-fill ${validatePercentages() ? 'valid' : 'invalid'}`}
                                                style={{ width: `${Math.min(comments.reduce((sum, { percentage }) => sum + percentage, 0), 100)}%` }}
                                            ></div>
                                        </div>
                                        <span className={validatePercentages() ? 'valid' : 'invalid'}>
                                            {comments.reduce((sum, { percentage }) => sum + percentage, 0)}%
                                        </span>
                                    </div>

                                </>
                            )}
                        </div>
                    </div>
                )}

                {/* Price Section */}
                <div className="price-section">
                    <span>Total Price:</span>
                    <span className="price-amount">{totalPrice.toFixed(2)} $SWARM</span>
                </div>

                {/* Action Button */}
                <div className="action-section">
                    {connected && publicKey ? (
                        <SolanaButton
                            type="send"
                            amount={totalPrice}
                            tokenMintAddress="6NPE8BZbY71L3EArLLjF2KLtJL7sJmYfysyMtHfEJkpW"
                            recipientAddress="F5Jxtobkhwu2SSgs9o4Yx1DchPGHADay9GGx1m37XLm4"
                            onTransactionComplete={handlePublish}
                        />
                    ) : (
                        <button className="connect-wallet" onClick={() => connect()}>
                            Connect Wallet
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SetupJobModal;