import React, { useState } from 'react';
import './ReleaseSwarmModal.css';

type ReleaseSwarmModalProps = {
    isVisible: boolean;
    onClose: () => void;
    onConfirm: (params: ReleaseParams) => void;
};

export type ReleaseParams = {
    target_running_profiles: number;
    loops: number;
    max_accounts_per_cycle?: number;
    profile_id_list?: string;
    max_level?: number;
    min_level?: number;
};

const ReleaseSwarmModal: React.FC<ReleaseSwarmModalProps> = ({ isVisible, onClose, onConfirm }) => {
    const [releaseParams, setReleaseParams] = useState<ReleaseParams>({
        target_running_profiles: 2,
        loops: 1,
    });

    const updateReleaseParam = (key: string, value: any) => {
        setReleaseParams((prev) => ({ ...prev, [key]: value }));
    };

    const handleConfirm = () => {
        onConfirm(releaseParams); // Pass parameters to the parent
        onClose(); // Close the modal
    };

    if (!isVisible) return null;

    return (
        <div className="release-swarm-modal">
            <div className="release-modal-content">
                <h3>Release Swarm</h3>
                <div className="release-form">
                    <label>
                        Target Running Profiles:
                        <input
                            type="number"
                            value={releaseParams.target_running_profiles}
                            onChange={(e) => updateReleaseParam('target_running_profiles', Number(e.target.value))}
                        />
                    </label>
                    <label>
                        Loops:
                        <input
                            type="number"
                            value={releaseParams.loops}
                            onChange={(e) => updateReleaseParam('loops', Number(e.target.value))}
                        />
                    </label>
                    <label>
                        Max Accounts Per Cycle (Optional):
                        <input
                            type="number"
                            value={releaseParams.max_accounts_per_cycle || ''}
                            onChange={(e) => updateReleaseParam('max_accounts_per_cycle', Number(e.target.value))}
                        />
                    </label>
                    <label>
                        Profile ID List (Optional, comma-separated):
                        <input
                            type="text"
                            value={releaseParams.profile_id_list || ''}
                            onChange={(e) => updateReleaseParam('profile_id_list', e.target.value)}
                        />
                    </label>
                    <label>
                        Max Level (Optional):
                        <input
                            type="number"
                            value={releaseParams.max_level || ''}
                            onChange={(e) => updateReleaseParam('max_level', Number(e.target.value))}
                        />
                    </label>
                    <label>
                        Min Level (Optional):
                        <input
                            type="number"
                            value={releaseParams.min_level || ''}
                            onChange={(e) => updateReleaseParam('min_level', Number(e.target.value))}
                        />
                    </label>
                </div>
                <div className="release-modal-actions">
                    <button onClick={handleConfirm}>Confirm</button>
                    <button onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default ReleaseSwarmModal;
