import React, { useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import './Comment.css';

interface CommentData {
    _id: string;
    text: string;
    media_location?: string;
    creatorAddress?: string;
}

interface CommentProps {
    comment: CommentData;
    onUpdate: (updatedComment: CommentData) => void; // Callback to update the comment in the parent component
    onDelete: (commentId: string) => void; // Callback to remove the comment in the parent component
}

const truncateAddress = (address: string | undefined) =>
    address ? `${address.slice(0, 6)}...${address.slice(-4)}` : 'Unknown Address';

const isVideoFile = (url: string | undefined): boolean => {
    if (!url) return false;
    const videoExtensions = ['mp4', 'webm', 'ogg', 'mov', 'avi', 'mkv'];
    const fileExtension = url.split('.').pop()?.toLowerCase();
    return videoExtensions.includes(fileExtension || '');
};

const Comment: React.FC<CommentProps> = ({ comment, creationWallet, onUpdate, onDelete }) => {
    const { publicKey } = useWallet();
    const connectedWallet = publicKey?.toBase58();
    const godModeWallets = (process.env.REACT_APP_GOD_MODE_WALLETS || '').split(',').map((wallet) => wallet.trim());
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:42069';

    const isAuthorized =
        godModeWallets.includes(connectedWallet || '') ||
        comment.creatorAddress === connectedWallet ||
        creationWallet === connectedWallet; // Check against creatorAddress and creationWallet

    const mediaUrl = comment.media_location ? `${backendUrl}${comment.media_location}` : null;

    const [isEditing, setIsEditing] = useState(false);
    const [editText, setEditText] = useState(comment.text);
    const [newMedia, setNewMedia] = useState<File | null>(null);

    const handleMediaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setNewMedia(e.target.files[0]);
        }
    };

    const handleEdit = async () => {
        if (!isEditing) {
            setIsEditing(true);
            return;
        }

        try {
            const formData = new FormData();
            formData.append('comment', JSON.stringify({ ...comment, text: editText }));
            if (newMedia) {
                formData.append('media', newMedia);
            }

            const response = await fetch(`${backendUrl}/api/comments/${comment._id}`, {
                method: 'PUT',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to update comment');
            }

            const updatedComment = await response.json();
            onUpdate(updatedComment.comment); // Update the comment in the parent component
            setIsEditing(false);
            setNewMedia(null); // Clear the selected media
        } catch (error) {
            console.error('Error updating comment:', error);
            alert('Failed to update comment');
        }
    };

    const handleDelete = async () => {
        const confirmDelete = window.confirm('Are you sure you want to delete this comment?');
        if (!confirmDelete) return;

        try {
            const response = await fetch(`${backendUrl}/api/comments/${comment._id}`, {
                method: 'DELETE',
            });

            if (!response.ok) {
                throw new Error('Failed to delete comment');
            }

            onDelete(comment._id); // Remove the comment from the parent component
        } catch (error) {
            console.error('Error deleting comment:', error);
            alert('Failed to delete comment');
        }
    };

    return (
        <div className="comment-card">
            {mediaUrl && !newMedia && (
                <div className="comment-media">
                    {isVideoFile(mediaUrl) ? (
                        <video
                            src={mediaUrl}
                            autoPlay
                            loop
                            muted
                            playsInline
                            className="comment-media-element"
                        />
                    ) : (
                        <img
                            src={mediaUrl}
                            alt="Media content"
                            className="comment-media-element"
                            onError={(e) => (e.currentTarget.style.display = 'none')}
                        />
                    )}
                </div>
            )}

            <div className="comment-content">
                {isEditing ? (
                    <>
                        <textarea
                            className="edit-textarea"
                            value={editText}
                            onChange={(e) => setEditText(e.target.value)}
                        />
                        <input type="file" accept="image/*,video/*" onChange={handleMediaChange} />
                    </>
                ) : (
                    <p className="comment-text">{comment.text}</p>
                )}
                <div className="comment-header">
                    <p className="comment-author">By: {truncateAddress(comment.creatorAddress)}</p>
                    {isAuthorized && (
                        <div className="comment-actions">
                            <button className="action-button edit" onClick={handleEdit}>
                                <FaEdit /> {isEditing ? 'Save' : 'Edit'}
                            </button>
                            <button className="action-button delete" onClick={handleDelete}>
                                <FaTrash /> Delete
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Comment;

