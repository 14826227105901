import React, { useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import BankList from '../../components/BankList/BankList';
import CreateBankModal from '../../components/CreateBankModal/CreateBankModal';
import './CommentBankPage.css';

const CommentBankPage: React.FC = () => {
    const { publicKey, connected } = useWallet();
    const GOD_MODE_WALLET = 'F5Jxtobkhwu2SSgs9o4Yx1DchPGHADay9GGx1m37XLm4';
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value);
    const handleModalToggle = () => setIsModalOpen(!isModalOpen);

    const walletAddress = publicKey?.toBase58() || null;
    const isGodMode = walletAddress === GOD_MODE_WALLET;

    return (
        <div className="comment-bank-page">
            <h1>Comment Banks</h1>
            {connected ? (
                <>
                    <div className="create-bank-section">
                        <button className="open-modal-button" onClick={handleModalToggle}>
                            Create Bank
                        </button>
                    </div>
                    <div className="search-bar">
                        <input
                            type="text"
                            placeholder="Search by UUID..."
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </div>
                    <BankList
                        searchTerm={searchTerm}
                        connectedWalletAddress={walletAddress || ''}
                        isGodMode={isGodMode}
                    />
                    {isModalOpen && <CreateBankModal onClose={handleModalToggle} />}
                </>
            ) : (
                <p>Please connect your wallet to view the banks.</p>
            )}
        </div>
    );
};

export default CommentBankPage;
