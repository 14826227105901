// src/pages/Home/Home.tsx
import React from 'react';
import Banner from '../../components/Banner/Banner';
import ExplanationBlock from '../../components/ExplanationBlock/ExplanationBlock';
import Metrics from '../../components/Metrics/Metrics';
import Connect from '../../components/Connect/Connect';
import Services from '../../components/Services/Services';
import './Home.css';

const Home: React.FC = () => {
    return (
        <div className="home">
            <Banner />
            <section className="content-section">
                <ExplanationBlock />
            </section>
            <section className="content-section">
                <Services />
            </section>
            <section className="content-section">
                <Metrics />
            </section>
            <section className="content-section">
                <Connect />
            </section>
        </div>
    );
};

export default Home;