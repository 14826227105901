// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comment-bank-page {
	max-width: 1200px;
	margin: 0 auto;
	padding: 20px;
}

.create-bank-section {
	margin-bottom: 30px;
}

.search-bar {
	margin: 20px 0;
	display: flex;
	justify-content: center;
}

	.search-bar input {
		width: 100%;
		max-width: 400px;
		padding: 10px;
		font-size: 1rem;
		border: 1px solid var(--primary-color);
		border-radius: 8px;
		outline: none;
		background: rgba(255, 255, 255, 0.1);
		color: var(--text-color);
	}
`, "",{"version":3,"sources":["webpack://./src/pages/CommentBankPage/CommentBankPage.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;CACjB,cAAc;CACd,aAAa;AACd;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,cAAc;CACd,aAAa;CACb,uBAAuB;AACxB;;CAEC;EACC,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,eAAe;EACf,sCAAsC;EACtC,kBAAkB;EAClB,aAAa;EACb,oCAAoC;EACpC,wBAAwB;CACzB","sourcesContent":[".comment-bank-page {\r\n\tmax-width: 1200px;\r\n\tmargin: 0 auto;\r\n\tpadding: 20px;\r\n}\r\n\r\n.create-bank-section {\r\n\tmargin-bottom: 30px;\r\n}\r\n\r\n.search-bar {\r\n\tmargin: 20px 0;\r\n\tdisplay: flex;\r\n\tjustify-content: center;\r\n}\r\n\r\n\t.search-bar input {\r\n\t\twidth: 100%;\r\n\t\tmax-width: 400px;\r\n\t\tpadding: 10px;\r\n\t\tfont-size: 1rem;\r\n\t\tborder: 1px solid var(--primary-color);\r\n\t\tborder-radius: 8px;\r\n\t\toutline: none;\r\n\t\tbackground: rgba(255, 255, 255, 0.1);\r\n\t\tcolor: var(--text-color);\r\n\t}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
