import React, { useState, useEffect } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import SwapCard from '../../components/SwapCard/SwapCard';
import WalletInfo from '../../components/Common/WalletInfo';
import './BuySwarmPage.css';
import { FaExchangeAlt } from 'react-icons/fa';
import {
    Connection,
    PublicKey,
    ParsedAccountData,
    VersionedTransaction,
} from '@solana/web3.js';
import { Buffer } from 'buffer';


const JUPITER_API_BASE = "https://quote-api.jup.ag/v6";
const SOL_MINT = "So11111111111111111111111111111111111111112"; // SOL Mint Address
const SWARM_MINT = "6NPE8BZbY71L3EArLLjF2KLtJL7sJmYfysyMtHfEJkpW"; // SWARM Mint Address
const PRIMARY_CONNECTION_URL = 'https://chaotic-crimson-star.solana-mainnet.quiknode.pro/a7cdf02f65bc61c5443446e5db4ea0fda4cd455e';

const BuySwarmPage: React.FC = () => {
    const { connected, publicKey, connect, sendTransaction } = useWallet();
    const [swapDirection, setSwapDirection] = useState(true); // true for "SOL to SWARM", false for "SWARM to SOL"
    const [inputAmount, setInputAmount] = useState('');
    const [outputAmount, setOutputAmount] = useState('0'); // Calculated quote value
    const [balances, setBalances] = useState<{ SOL: number; SWARM: number }>({ SOL: 0, SWARM: 0 });
    const [loadingQuote, setLoadingQuote] = useState(false);
    const [quote, setQuote] = useState<any>(null); // Store the fetched quote
    const [decimals, setDecimals] = useState<{ SOL: number; SWARM: number }>({ SOL: 9, SWARM: 6 });

    const connection = new Connection(PRIMARY_CONNECTION_URL);

    useEffect(() => {
        const fetchDecimals = async () => {
            try {
                const solDecimals = await getTokenDecimals(SOL_MINT);
                const swarmDecimals = await getTokenDecimals(SWARM_MINT);
                setDecimals({ SOL: solDecimals, SWARM: swarmDecimals });
            } catch (error) {
                console.error("Error fetching token decimals:", error);
            }
        };

        fetchDecimals();
    }, []);

    useEffect(() => {
        if (inputAmount) {
            fetchQuote(inputAmount);
        }
    }, [inputAmount, swapDirection]);

    const handleBalancesUpdate = (fetchedBalances: { [key: string]: number }) => {
        console.log("Balances fetched:", fetchedBalances);
        setBalances({
            SOL: fetchedBalances['SOL'] || 0,
            SWARM: fetchedBalances['SWARM'] || 0,
        });
    };

    const getTokenDecimals = async (mintAddress: string): Promise<number> => {
        const mintPubkey = new PublicKey(mintAddress);
        const accountInfo = await connection.getParsedAccountInfo(mintPubkey);

        if (accountInfo.value) {
            const data = accountInfo.value.data;
            if ('parsed' in data) {
                const mintData = (data as ParsedAccountData).parsed.info;
                return mintData.decimals;
            }
        }
        throw new Error(`Failed to fetch token decimals for mint: ${mintAddress}`);
    };
    const fetchQuote = async (amount: string) => {
        if (!publicKey || parseFloat(amount) === 0) return;
        setLoadingQuote(true);

        const inputMint = swapDirection ? SOL_MINT : SWARM_MINT;
        const outputMint = swapDirection ? SWARM_MINT : SOL_MINT;
        const smallestUnitAmount = Math.floor(
            parseFloat(amount) * Math.pow(10, swapDirection ? decimals.SOL : decimals.SWARM)
        );

        console.log("Fetching quote for:", { inputMint, outputMint, amount: smallestUnitAmount });

        try {
            const response = await fetch(
                `${JUPITER_API_BASE}/quote?inputMint=${inputMint}&outputMint=${outputMint}&amount=${smallestUnitAmount}&slippageBps=50`
            );
            const data = await response.json();
            console.log("Quote fetched:", data);

            // Check if data contains outAmount directly
            if (data?.outAmount) {
                setQuote(data); // Save the quote object directly
                console.log("Quote set for swap:", data);

                const outAmount = parseFloat(data.outAmount) / Math.pow(10, swapDirection ? decimals.SWARM : decimals.SOL);
                console.log("Output amount calculated:", outAmount);
                setOutputAmount(outAmount.toFixed(6));
            } else {
                console.warn("No valid quote received. Missing 'outAmount'.");
                setQuote(null);
                setOutputAmount("0");
            }
        } catch (error) {
            console.error("Failed to fetch quote:", error);
            setQuote(null);
            setOutputAmount("0");
        } finally {
            setLoadingQuote(false);
        }
    };
    const performSwap = async () => {
        if (!connected || !publicKey || !sendTransaction || !quote) {
            console.error("Missing required parameters for swap execution.");
            alert("Please ensure your wallet is connected, and a quote has been fetched.");
            return;
        }

        console.log("Performing swap with quote:", quote);

        try {
            const response = await fetch(`${JUPITER_API_BASE}/swap`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    quoteResponse: quote,
                    userPublicKey: publicKey.toBase58(),
                    wrapAndUnwrapSol: true,
                    dynamicSlippage: { maxBps: 50 },
                }),
            });

            if (!response.ok) {
                const errorResponse = await response.json();
                console.error("Swap API returned error:", errorResponse);
                alert(`Swap API Error: ${errorResponse.message || response.statusText}`);
                return;
            }

            const data = await response.json();
            console.log("Swap API Response:", data);

            if (data?.swapTransaction) {
                // Use Buffer to deserialize the transaction
                const transactionBuffer = Buffer.from(data.swapTransaction, "base64");
                const transaction = VersionedTransaction.deserialize(transactionBuffer);

                const signature = await sendTransaction(transaction, connection);
                console.log(`Swap successful. Transaction ID: ${signature}`);

                await connection.confirmTransaction(signature, "finalized");
                alert(`Swap successful! Transaction ID: ${signature}`);
            } else {
                console.error("Swap transaction not returned by API.");
                alert("Swap failed. No transaction returned by API.");
            }
        } catch (error) {
            console.error("Swap failed:", error);
            alert("Swap failed. Please try again.");
        }
    };



    const toggleSwapDirection = () => {
        setSwapDirection(!swapDirection);
        setInputAmount(outputAmount);
        setOutputAmount("0"); // Reset for a new quote
    };

    const getLabelsAndAssets = () => {
        const inputBalance = swapDirection ? balances.SOL : balances.SWARM; // User's balance of input asset
        const outputBalance = swapDirection ? balances.SWARM : balances.SOL; // User's balance of output asset

        return {
            inputLabel: "Input",
            outputLabel: "Output",
            inputAsset: swapDirection ? "SOL" : "SWARM",
            outputAsset: swapDirection ? "SWARM" : "SOL",
            inputBalance: inputBalance.toFixed(2), // User's input balance
            outputBalance: outputBalance.toFixed(2), // User's output balance
        };
    };

    const { inputLabel, outputLabel, inputAsset, outputAsset, inputBalance, outputBalance } =
        getLabelsAndAssets();

    return (
        <div className="buy-swarm-page">
            <h2 className="page-title">Swap SOL & SWARM</h2>
            <p className="page-description">Seamlessly swap SOL for $SWARM tokens and vice versa.</p>

            {connected && publicKey && (
                <WalletInfo publicKey={publicKey.toBase58()} onBalancesUpdate={handleBalancesUpdate} />
            )}

            <div className="swap-container">
                <SwapCard
                    assetType={inputLabel}
                    assetName={inputAsset}
                    balance={inputBalance}
                    onInputChange={(value) => {
                        const numericValue = parseFloat(value);
                        if (numericValue <= parseFloat(inputBalance)) {
                            setInputAmount(value);
                        }
                    }}
                />
                <FaExchangeAlt className="swap-arrow" onClick={toggleSwapDirection} />
                <SwapCard
                    assetType={outputLabel}
                    assetName={outputAsset}
                    balance={outputBalance} // Show user's wallet balance for output asset
                    onInputChange={() => { }}
                    disabled={true} // Disable input for balance
                    outputValue={outputAmount} // Show quote value here
                />
            </div>
            {connected ? (
                <button
                    className="swap-button"
                    onClick={performSwap}
                    disabled={loadingQuote || !inputAmount}
                >
                    {loadingQuote ? "Fetching Quote..." : "Swap"}
                </button>
            ) : (
                <button className="connect-wallet-button" onClick={connect}>
                    Connect Wallet
                </button>
            )}
        </div>
    );
};

export default BuySwarmPage;
